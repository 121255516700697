import FeatureStep1 from './Steps/FeatureStep1'
import FeatureStep2 from './Steps/FeatureStep2'
import FeatureStep3 from './Steps/FeatureStep3'
import FeatureStep4 from './Steps/FeatureStep4'
import FeatureStep5 from './Steps/FeatureStep5'
import FeatureStep6 from './Steps/FeatureStep6'
import StickySection from './Steps/StickySection'


// const FeatureComponent2 = () => {

//     return (<><div className='space-y-32 wrapper md:block hidden'>
//         <StickySection><FeatureStep1 /></StickySection>
//         <StickySection><FeatureStep2 /></StickySection>
//         <StickySection><FeatureStep3 /></StickySection>
//         <StickySection><FeatureStep4 /></StickySection>
//         <StickySection><FeatureStep5 /></StickySection>
//         <StickySection><FeatureStep6 /></StickySection>
//          </div>
//          <div className='space-y-32 wrapper max-md:block hidden'>
//        <FeatureStep1 />
//        <FeatureStep2 />
//        <FeatureStep3 />
//        <FeatureStep4 />
//        <FeatureStep5 />
//        <FeatureStep6 />
//        </div>
//          </>)
// }

const FeatureComponent2 = () => {

    return (<div className='space-y-32 max-sm:space-y-20 wrapper max-sm:mt-6'>
        <FeatureStep1 />
        <FeatureStep2 />
        <FeatureStep3 />
        <FeatureStep4 />
        <FeatureStep5 />
        <FeatureStep6 />

    </div>)
}



export default FeatureComponent2