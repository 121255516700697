import React from 'react'
import { motion } from 'framer-motion';

const FeatureComponent1 = () => {

    return (<div className='space-y-4 wrapper'>
        <div className='text-center'>
            <p className='mx-auto px-4 py-1 bg-gradient-to-l  font-semibold rounded-full from-lime-200 to-lime-500 text-white border-8 border-lime-100 inline-block'>
                Features
            </p>
        </div>
        <h3 className='text-xl text-center md:text-[44px] md:leading-[1.2] font-semibold'>Detailed Features Overview</h3>
        <div className='w-full md:py-4'>
          <SubProductComponent1/>
        </div>
        <p className='text-[28px] text-center md:text-[44px]  leading-[1.2] md:font-semibold font-medium md:py-8 py-2'>What makes us the best app for you is our 
        <span style={{ color: "rgb(133, 196, 65)" }}> tailored features</span> and ongoing improvements</p>
    </div>)
}

const SubProductComponent1 = () => {
    const imageVariants = {
      initial: { opacity: 0, scale: 0.8, x: -50 },
      animate: {
        opacity: 1,
        scale: 1,
        x: 0,
        transition: { duration: 1, ease: "easeOut" }
      }
    };
  
    const imageVariants2 = {
      initial: { opacity: 0, scale: 1.2, rotate: -20 },
      animate: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: { duration: 1.2, ease: "easeInOut" }
      }
    };
  
    const imageVariants3 = {
      initial: { opacity: 0, x: 100 },
      animate: {
        opacity: 1,
        x: 0,
        transition: { duration: 1.5, ease: "easeInOut" }
      }
    };
  
    const imageVariants4 = {
      initial: { opacity: 0, scale: 0.8, rotate: 10 },
      animate: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: { duration: 1.3, ease: "easeInOut" }
      }
    };
  
    const imageVariants5 = {
      initial: { opacity: 0, y: 50 },
      animate: {
        opacity: 1,
        y: 0,
        transition: { duration: 1.4, ease: "easeInOut" }
      }
    };
  
    const imageVariants6 = {
      initial: { opacity: 0, x: -50 },
      animate: {
        opacity: 1,
        x: 0,
        transition: { duration: 1.6, ease: "easeInOut" }
      }
    };
  
    return (
      <div className='relative w-full overflow-hidden'>
        <motion.img
          src="/assets/product/wood1.png"
          alt="wood"
          className='w-full max-h-[550px] rounded-2xl'
          variants={imageVariants}
          initial="initial"
          animate="animate"
        />
        <motion.img
          src="/assets/product/macbook.png"
          alt="macbook"
          className='absolute top-[20%] left-[20%] w-[70%] xl:w-[800px]'
          variants={imageVariants2}
          initial="initial"
          animate="animate"
        />
        <motion.img
          src="/assets/product/watch.png"
          alt="watch"
          className='absolute top-[0] right-[10%] max-lg:w-[5%]'
          variants={imageVariants3}
          initial="initial"
          animate="animate"
        />
        <motion.img
          src="/assets/product/phone.png"
          alt="phone"
          className='absolute top-[50%] right-[9%] max-lg:w-[10%] max-xl:w-[15%]'
          variants={imageVariants4}
          initial="initial"
          animate="animate"
        />
        <motion.img
          src="/assets/product/pods.png"
          alt="pods"
          className='absolute bottom-[8%] right-[1%] max-lg:w-[10%]'
          variants={imageVariants5}
          initial="initial"
          animate="animate"
        />
        <motion.img
          src="/assets/product/book.png"
          alt="book"
          className='absolute top-[10%] max-lg:w-[20%]'
          variants={imageVariants6}
          initial="initial"
          animate="animate"
        />
        <motion.img
          src="/assets/product/pen.png"
          alt="pen"
          className='absolute bottom-[8%] left-[10%] max-lg:w-[12%]'
          variants={imageVariants}
          initial="initial"
          animate="animate"
        />
      </div>
    );
  };
// const SubProductComponent1 = () => {
//     return (
//         <div className='relative w-full overflow-hidden'>
//             <img
//                 src="/assets/product/wood.png"
//                 alt="wood"
//                 className='w-full max-h-[550px] rounded-2xl'
//             />
//             <img
//                 src="/assets/product/macbook.png"
//                 alt="macbook"
//                 className='absolute top-[20%] left-[20%] w-[70%] xl:w-[800px]'
//             />
//             <img
//                 src="/assets/product/watch.png"
//                 alt="watch"
//                 className='absolute top-[0] right-[10%] max-lg:w-[5%]   '
//             />
//             <img
//                 src="/assets/product/phone.png"
//                 alt="phone"
//                 className='absolute top-[50%] right-[9%] max-lg:w-[10%] max-xl:w-[15%]'
//             />
//             <img
//                 src="/assets/product/pods.png"
//                 alt="pods"
//                 className='absolute bottom-[8%] right-[1%]  max-lg:w-[10%] '
//             />
//             <img
//                 src="/assets/product/book.png"
//                 alt="book"
//                 className='absolute top-[10%] max-lg:w-[20%]'
//             />
//             <img
//                 src="/assets/product/pen.png"
//                 alt="pen"
//                 className='absolute bottom-[8%] left-[10%] max-lg:w-[12%]'
//             />
//         </div>
//     );
// };

export default FeatureComponent1