import { cardVariantsY } from "../../../../constants";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
const FeatureStep6 = () => {
    return (<div className='relative '>
        <div className=' relative grid md:grid-cols-2 z-10 grid-cols-1 gap-10 items-center '>
            <div className='md:space-y-5 md:px-12 px-4'>
                {/* <p className='text-indigo-500'>Simple Approval Requests</p>
                <p className='text-indigo-500 md:text-7xl text-3xl font-semibold'>06</p> */}
                <p className='text-indigo-500 inline-block rounded-full text-[12px] bg-white p-2 px-3 '>Simple Approval Requests</p>
               <p className="text-3xl md:text-7xl font-semibold bg-gradient-to-t from-indigo-400 to-indigo-500 bg-clip-text text-transparent">
                   06
               </p>
                <p className='md:text-3xl font-semibold text-2xl max-md:font-medium'>Request Approvals as if Messaging a Friend !</p>
                <p className='sm:text-sm secondary-textcolor '>Whether it’s task completion, bills, or document sign-offs, request approvals easily and efficiently.</p>
            </div>
            <div className='flex justify-center items-end '>
                <div className='relative'>
                    <img className=" max-h-[600px]  md:w-full w-[70%] mx-auto  object-cover" loading="lazy" src="/assets/product/step6phone.png" alt="phone" />
                    <div className="absolute bottom-[0] right-[5%] max-md:right-0  w-full">
                        <SubFeatureStep1 />
                    </div>
                    <div className="absolute bottom-[40%] max-md:right-5 right-0 ">
                        <SubFeatureStep3 />
                    </div>
                </div>

            </div>
        </div>
        <div className='bg-gradient-to-t z-0 absolute md:bottom-0 bottom-8 from-white to-indigo-50 p-4 rounded-3xl w-full h-full  md:h-[500px]'></div>
    </div>
    )
}

const SubFeatureStep1 = () => {
    return (
        <div className='md:w-full max-sm:max-w-[350px] h-1 bg-indigo-500 '> </div>
    )
}




const SubFeatureStep3 = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.4 });


  const imageVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6, ease: 'easeInOut' } },
  };
  
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: 'easeInOut',
        type: 'spring',
        stiffness: 300,
      },
    },
  };
  return (
    <div className='relative p-2 bg-white rounded-xl flex gap-2 items-center'>
      <motion.img
        ref={ref}
        variants={imageVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className="max-md:w-[30%] p-2 bg-gradient-to-tl from-lime-300 to-lime-500 rounded-lg z-10 max-h-full object-cover max-w-full"
        alt=""
        src="/assets/product/check.svg"
      />
      <motion.p
        variants={textVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className='text-lg font-semibold'
      >
        Approved!
      </motion.p>
    </div>
  );
};


// const SubFeatureStep3 = () => {
//     return (
//         <div className='relative  p-2 bg-white rounded-xl flex gap-2 items-center'>
//         <img className="max-md:w-[30%] p-2 bg-gradient-to-tl from-lime-300 to-lime-500 rounded-lg z-10 max-h-full object-cover max-w-full" alt="" src="/assets/product/check.svg" />
//   <p>Approved!</p>
//    </div>
//     )
// }
export default FeatureStep6