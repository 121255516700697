import { Button } from '../../ui/button'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const HomeComponent2 = () => {
    return (
        <div className="relative">
            <div className='bg-gradient-to-b from-white to-lime-50 md:mt-5'>
                <div className='wrapper'>
                    <img
                        className="self-stretch relative max-w-full overflow-hidden max-h-full object-cover shrink-0"
                        loading="lazy"
                        alt=""
                        src="/assets/home/macbook-pro-mockup-front-view-31@2x.png"
                    />

                </div>
            </div>
            <SubHomeComponent2 />
        </div>
    )
}

const SubHomeComponent2 = () => {
    const navigate = useNavigate()
    return (
        <div className='bg-white  absolute xl:top-[600px] md:top-[400px] sm:top-[300px] top-[180px] w-full'>
            <AutoScroll/>
            <div className='wrapper md:py-12'>
                <div className='grid md:grid-cols-12 grid-cols-1 items-center gap-4 md:gap-10'>
                    <div className='md:col-span-5 relative max-md:w-[80%] mx-auto'>
                        <img className="relative z-10 max-h-full overflow-hidden shrink-0  md:max-w-[300px] lg:max-w-[400px] max-w-full sm:max-w-[400px]" alt="" src="/assets/home/homeGroup.png" />
                        <img className="absolute z-0 md:bottom-[-10px] bottom-[-20px] left-[0%] sm:left-[-10%] max-md:max-w-[130px] max-h-full overflow-hidden shrink-0 max-w-full " alt="" src="/assets/home/frame-11.svg" />

                    </div>
                    <div className='md:col-span-7 max-md:mt-6 flex-col flex gap-6 max-md:items-center text-wrap '>
                        <p className='text-[44px] leading-[1.2] max-md:text-xl max-md:text-center font-semibold '>The Spark Behind <span style={{ color: "rgb(133, 196, 65)" }} >AssignIt</span>,
                            Where Simplicity Meets <span style={{ color: "rgb(133, 196, 65)" }}>Efficiency</span></p>
                        <Button onClick={()=>navigate("/about")} className="buttonColor  h-[50px] hover:bg-indigo-700 rounded-full max-w-[230px]  md:px-12 px-8 md:text-lg md:font-medium md:py-6">
                            Read Our Story
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    )
}

const AutoScroll = () => {
    return (
      <div  className="w-full md:h-[72px] h-[52px]  overflow-hidden" style={{backgroundColor:"#85C441"}}>
        <div className="space-x-4 whitespace-nowrap flex items-center scroll-content h-full text-white">
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
        <div className='flex gap-4 items-center '>
        <div>
        <img src="/assets/scrollImg.png" alt="scrollImg" className='max-w-8 max-h-8'/>
        </div>
        <p className='md:text-[28px] text-xl font-medium'>Coming Soon</p>
        </div>
    
        </div>
      </div>
    );
  };


export default HomeComponent2