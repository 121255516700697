import { useEffect, useState } from 'react';
import { Button } from '../../../components/ui/button'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-scroll';

const HomeComponent1 = () => {
    const [showEasyNow, setShowEasyNow] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setShowEasyNow(true), 1000); // Delay before "easy now" appears
        return () => clearTimeout(timer);
    }, []);


    return (<>
        <div className='wrapper md:mt-5 '>
            <div className='flex max-md:hidden justify-end max-w-5xl '>
                <div className='relative'>
                    <div className=" bg-fuchsia-600 rounded-full font-medium py-1 text-white px-3" style={{ fontSize: "12px" }}>
                        Efficiency
                    </div>
                    <img
                        className="absolute top-[-15px] left-[-20px] w-7 h-7 overflow-hidden z-[1]"
                        loading="lazy"
                        alt=""
                        src="assets/home/frame.svg"
                    />
                </div>
            </div>
            <h1 className="max-md:hidden font-semibold mt-2 text-5xl text-center">
                Doing things is easy, getting
            </h1>
            <div className='md:hidden relative'>
                {/* <h1 className=" font-semibold   text-3xl text-center">
                    Doing things is easy, getting things done is difficult
                </h1> */}
                <h1 className=" font-semibold   text-3xl text-center">
                    Doing things is easy, getting things done is <span  className="text-lime-600">now easier</span>
                   {/*   <AnimatePresence>
                        {!showEasyNow && (
                            <motion.span
                                key="difficult"
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: .5 }}
                            >
                                difficult
                            </motion.span>
                        )}
                    </AnimatePresence>
                    {showEasyNow && (
                        <motion.span
                            key="easy-now"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }} // Duration of the fade-in effect
                            className="text-lime-600"
                        >
                            easy now
                        </motion.span>
                    )} */}
                </h1>
                <div className='absolute bottom-[-4px]'>
                    <div className='relative'>
                        <div className=" bg-green-600 rounded-full font-medium py-1 text-white px-3" style={{ fontSize: "12px" }}>
                            Productivity
                        </div>
                        <img
                            className="absolute top-[-15px] left-[-20px] w-7 h-7 overflow-hidden z-[1]"
                            loading="lazy"
                            alt=""
                            src="assets/home/frame-2.svg"
                        />
                    </div>
                </div>
            </div>
            <div className=" flex max-md:hidden  md:ml-20 lg:ml-40 mt-2 flex-row items-center md:gap-10 lg:gap-20">
                <div className='relative'>
                    <div className=" bg-green-600 rounded-full font-medium py-1 text-white px-3" style={{ fontSize: "12px" }}>
                        Productivity
                    </div>
                    <img
                        className="absolute top-[-15px] left-[-20px] w-7 h-7 overflow-hidden z-[1]"
                        loading="lazy"
                        alt=""
                        src="assets/home/frame-2.svg"
                    />
                </div>
                {/* <h1 className="font-semibold mt-2 text-5xl text-center">
                    things done is difficult
                </h1> */}
                <h1 className="font-semibold mt-2 text-5xl text-center">
                    things done is <span  className="text-lime-600">now easier</span>
                   {/*  <AnimatePresence>
                        {!showEasyNow && (
                            <motion.span
                                key="difficult"
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: .5 }}
                            >
                                difficult
                            </motion.span>
                        )}
                    </AnimatePresence>
                    {showEasyNow && (
                        <motion.span
                            key="easy-now"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1 }} // Duration of the fade-in effect
                            className="text-lime-600"
                        >
                            easy now
                        </motion.span>
                    )} */}
                </h1>
            </div>
            <div className='flex max-md:hidden justify-center md:pl-16 secondary-textcolor my-4 mx-auto max-w-4xl text-center'>
                <h3 className='leading-relaxed'>
                    Seamlessly oversee your projects, tasks, and team interactions from a single platform. Enhance team productivity, streamline task management, and accomplish more with AssignIt.
                </h3>
            </div>
            <div className='relative max-md:my-2'>
                <div className="flex justify-center my-3">
                    <Button asChild className="buttonColor hover:cursor-pointer h-[50px] rounded-full  hover:bg-indigo-700  md:px-12 px-8 md:text-lg md:font-medium md:py-6">
                    <Link to="joinBeta" smooth={true} duration={2000}>Join Beta</Link>
                    </Button>
                </div>
                <div className='flex absolute lg:right-60 md:right-20  right-[-4px] md:top-5  top-[-20px] justify-end max-w-5xl '>
                    <div className='relative'>
                        <div className=" bg-orange-400 rounded-full font-medium py-1 text-white px-3" style={{ fontSize: "12px" }}>
                            Growth
                        </div>
                        <img
                            className="absolute top-[-15px] left-[-20px] w-7 h-7 overflow-hidden z-[1]"
                            loading="lazy"
                            alt=""
                            src="assets/home/frame-1.svg"
                        />
                    </div>
                </div>
            </div>
        </div>
          <div className="absolute top-0 w-full overflow-hidden">
          <SubHomeComponent1/>
      </div>
      </>
    )
}

const SubHomeComponent1=()=>{
    return <div className='w-full md:h-[250px] h-[150px] relative -z-10'>
        <img src="/assets/home/vector1.svg" alt="vector1" className='absolute left-0 top-0' />
        <img src="/assets/home/vector2.svg" alt="vector2" className='absolute md:left-40 left-0 top-0 ' />
        <img src="/assets/home/vector3.svg" alt="vector3" className='absolute md:right-40 right-2 top-0' />
        <img src="/assets/home/vector4.svg" alt="vector4" className='absolute right-0 top-0' />
    </div>
}
export default HomeComponent1;

