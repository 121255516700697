import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MobileNav from "./MobileNav";
import SubHeader from "./SubHeader";
import { Button } from "../../../components/ui/button";
import { Link } from 'react-scroll';
import { scrollToTarget } from "../../../constants";

const Header = ({ scrolled }: { scrolled: boolean }) => {

  const handleJoinBetaClick = () => {
    scrollToTarget('joinBeta', 2000);
  };

  /*   const handleJoinBetaClick = () => {
      if (location.pathname === '/contact'||location.pathname === '/features') {
        navigate('/'); 
        setTimeout(() => {
          scrollToTarget('joinBeta', 2000); 
        }, 300); 
      } else {
        scrollToTarget('joinBeta', 2000); 
      }
    }; */

  return (
    <header className={`w-full top-0 z-50 transition-all duration-500  bg-white fixed bg-opacity-80 `}>
      <div className="flex justify-between items-center wrapper">
        <NavLink to="/">
          <img
            className="h-8 w-[124.2px] relative"
            loading="lazy"
            alt=""
            src="/assets/home/group-19.svg"
          />
        </NavLink>
        <div>
          <SubHeader />
        </div>
        <MobileNav />
        <div className="flex flex-row items-center justify-center gap-4 max-md:hidden">
          {/*   <NavLink to={"/login"} className="textColor font-medium">
          Login
        </NavLink> */}
          <Button onClick={handleJoinBetaClick} className="buttonColor hover:cursor-pointer rounded-full hover:bg-indigo-700 " >
            Join Beta
          </Button>
          {/*  <Button asChild className="buttonColor hover:cursor-pointer rounded-full hover:bg-indigo-700 " >
        <Link to="joinBeta" smooth={true} duration={2000}>Join Beta</Link>
        </Button> */}
        </div>
      </div>
      <div
        className={`absolute inset-0 w-full h-full pointer-events-none transition-opacity duration-300 ${scrolled ? 'opacity-60' : 'opacity-100'
          }`}
        style={{ zIndex: -1 }}
      ></div>
    </header>
  );
};

export default Header;


