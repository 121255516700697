import { SubNavigation } from "./NavItems";


const SubHeader = () => {
  return ( 
<header className="w-full hidden md:block sticky top-0 z-50 bg-opacity-0">
  <div className="flex gap-4 flex-wrap ">
    <SubNavigation />
  </div>
</header>

  );
};

export default SubHeader;
