import AboutComponent2 from '../components/shared/About/AboutComponent2'
import AboutComponent1 from '../components/shared/About/AboutComponent1'
import AboutComponent3 from '../components/shared/About/AboutComponent3'
import AboutComponent4 from '../components/shared/About/AboutComponent4'
import BlogComponent3 from '../components/shared/Blog/BlogComponent3'
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {
  return (
   <>
    <Helmet>
        <meta name="title" content="AssignIt: The Ultimate Solution for Task Management" />
        <meta name="description" content="Discover AssignIt, the intuitive task management tool designed to simplify your workflow. Seamlessly manage projects of any size with ease and efficiency" />
        <meta name="keywords" content="construction management software, gantt chart excel, asset management software, asana project management, to do list app, fleet management software, task management software, todolist, atlassian jira, project tracker" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="application/xml; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
   <AboutComponent1/>
   <AboutComponent2/>
   <AboutComponent3/>
{/*    <AboutComponent4/> */}
<BlogComponent3/>
   </>
  )
}

export default AboutUs