import FeatureComponent2 from "../components/shared/Features/FeatureComponent2"
import FeatureComponent1 from "../components/shared/Features/FeatureComponent1"
import BlogComponent3 from "../components/shared/Blog/BlogComponent3"
import { Helmet } from "react-helmet-async"


const Features = () => {
  return (
    <>
     <Helmet>
        <meta name="title" content="Mastering Task Management with AssignIt Features" />
        <meta name="description" content="Enjoy effortless task management with AssignIt. Decompose tasks, track progress in real-time, and remain connected through intuitive updates and approval processes" />
        <meta name="keywords" content="project planner, task tracker, project tracking software, jira tool, task management app, best to do list app, customer relationship management software, pm tools, pomodoro technique, tools box" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="application/xml; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
    <FeatureComponent1/>
    <FeatureComponent2/>
   <BlogComponent3/>
    </>
  )
}

export default Features