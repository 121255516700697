import HomeComponent2 from "../components/shared/Home/HomeComponent2"
import HomeComponent1 from "../components/shared/Home/HomeComponent1"
import HomeComponent3 from "../components/shared/Home/HomeComponent3"
import HomeComponent4 from "../components/shared/Home/HomeComponent4"
import HomeComponent5 from "../components/shared/Home/HomeComponent5"
import BlogComponent3 from "../components/shared/Blog/BlogComponent3"
import { Helmet } from 'react-helmet-async';


const Home = () => {

  return (
    <>
      <Helmet>
        <meta name="title" content="Elevate Your Task Management Game with AssignIt" />
        <meta name="description" content="Experience effortless task management with AssignIt. Designed to enhance productivity, this tool helps you streamline your daily tasks and conquer deadlines with ease" />
        <meta name="keywords" content="task management, project management tool, google task manager, project management software, automation software, gantt chart, microsoft project, jira software, flowchart maker, ms project" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="application/xml; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <HomeComponent1 />
      <HomeComponent2 />
      <HomeComponent3 />
      <HomeComponent4 />
      {/* <HomeComponent5/> */}
      <BlogComponent3 />
    </>
  )
}




export default Home


