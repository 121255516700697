import { cardVariantsY } from "../../../../constants";
import { motion } from "framer-motion";
const FeatureStep5 = () => {
    return (<div className='relative '>
        <div className=' relative grid md:grid-cols-2 z-10 grid-cols-1 gap-10 items-center '>
            <div className='md:space-y-5 md:px-12 px-4'>
                {/* <p className='text-indigo-500'>Task Communication and Media</p>
                <p className='text-indigo-500 md:text-7xl text-3xl font-semibold'>05</p> */}
                <p className='text-indigo-500 inline-block rounded-full text-[12px] bg-white p-2 px-3 '>Task Communication and Media</p>
               <p className="text-3xl md:text-7xl font-semibold bg-gradient-to-t from-indigo-400 to-indigo-500 bg-clip-text text-transparent">
                   05
               </p>
                <p className='md:text-3xl font-semibold text-2xl max-md:font-medium'>Stay Connected with Task Updates as if Messaging on Popular Apps!</p>
                <p className='sm:text-sm secondary-textcolor '>Post updates, ask questions, and manage media all within the task details page.</p>
            </div>
            <div className='flex justify-center items-end '>
                <div className='relative'>
                    <img className=" max-h-[600px]  md:w-full w-[70%] mx-auto  object-cover" loading="lazy" src="/assets/product/step5phone.png" alt="phone" />
                    <motion.div className="absolute top-[20%] right-[10%] md:right-[-10%]" initial="hidden"  viewport={{ once: false, amount: 0.4 }} transition={{ staggerChildren: 0.3 }} whileInView="visible">
                        <SubFeatureStep1 />
                    </motion.div>
                    <motion.div className="absolute bottom-[40%] left-[7%] md:left-[-10%]" initial="hidden"  viewport={{ once: false, amount: 0.4 }} transition={{ staggerChildren: 0.3 }} whileInView="visible">
                        <SubFeatureStep2 />
                    </motion.div>
                    <motion.div className="absolute bottom-[25%] right-0 md:right-[-15%]" initial="hidden"  viewport={{ once: false, amount: 0.4 }} transition={{ staggerChildren: 0.3 }} whileInView="visible">
                        <SubFeatureStep3 />
                    </motion.div>
                </div>

            </div>
        </div>
        <div className='bg-gradient-to-t z-0 absolute md:bottom-0 bottom-8 from-white to-indigo-50 p-4 rounded-3xl w-full h-full  md:h-[550px] lg:h-[500px]'></div>
    </div>
    )
}

const SubFeatureStep1 = () => {
    return (
        <motion.div variants={cardVariantsY} className='relative md:w-[72px] md:h-[72px] w-[54px] h-[54px] bg-gradient-to-tl from-indigo-300 to-indigo-500 rounded-xl flex justify-center items-center'>
            <img className=" w-[65%] z-10 max-h-full object-cover max-w-full" alt="" src="/assets/product/folder.svg" />
       </motion.div>
    )
}
const SubFeatureStep2 = () => {
    return (
        <motion.div variants={cardVariantsY} className='relative md:w-[72px] md:h-[72px] w-[54px] h-[54px] bg-gradient-to-tl from-lime-300 to-lime-500 rounded-xl flex justify-center items-center'>
            <img className=" w-[65%] z-10 max-h-full object-cover max-w-full" alt="" src="/assets/product/file.svg" />
       </motion.div>
    )
}
const SubFeatureStep3 = () => {
    return (
        <motion.div variants={cardVariantsY} className='relative md:w-[102px] md:h-[34px] w-[96px] h-[34px] bg-white rounded-xl flex justify-center items-center'>
        <img className=" w-[65%] z-10 max-h-full object-cover max-w-full" alt="" src="/assets/product/combinedshape.svg" />
   </motion.div>
    )
}
/* const SubFeatureStep1 = () => {
    return (
        <div className='relative md:w-[72px] md:h-[72px] w-[54px] h-[54px] bg-gradient-to-tl from-indigo-300 to-indigo-500 rounded-xl flex justify-center items-center'>
            <img className=" w-[65%] z-10 max-h-full object-cover max-w-full" alt="" src="/assets/product/folder.svg" />
       </div>
    )
}
const SubFeatureStep2 = () => {
    return (
        <div className='relative md:w-[72px] md:h-[72px] w-[54px] h-[54px] bg-gradient-to-tl from-lime-300 to-lime-500 rounded-xl flex justify-center items-center'>
            <img className=" w-[65%] z-10 max-h-full object-cover max-w-full" alt="" src="/assets/product/file.svg" />
       </div>
    )
}
const SubFeatureStep3 = () => {
    return (
        <div className='relative md:w-[102px] md:h-[34px] w-[96px] h-[34px] bg-white rounded-xl flex justify-center items-center'>
        <img className=" w-[65%] z-10 max-h-full object-cover max-w-full" alt="" src="/assets/product/combinedshape.svg" />
   </div>
    )
} */
export default FeatureStep5