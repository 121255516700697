import ContactComponent2 from '../components/shared/Contact/ContactComponent2'
import AboutComponent4 from '../components/shared/About/AboutComponent4'
import ContactComponent1 from '../components/shared/Contact/ContactComponent1'
import BlogComponent3 from '../components/shared/Blog/BlogComponent3'
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  return (
   <>
      <Helmet>
        <meta name="title" content="The AssignIt Contact Page: Your Gateway to Support" />
        <meta name="description" content="Have questions about AssignIt? Our Contact Page provides insights into task management, team collaboration, and account setup. Get in touch today!" />
        <meta name="keywords" content="office deployment tool, project cycle management, risk breakdown structure, project planning in software project management, todo list application, best to do list app, project management system, productivity apps, project portfolio management, scribe tool, task management programs, project mgmt software, jira project management software, task and project management software" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="application/xml; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
   <ContactComponent1/>
  {/*  <ContactComponent2/> */}
   <AboutComponent4/>
   <BlogComponent3/>
   </>
  )
}

export default Contact