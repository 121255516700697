import { Button } from '../../../../components/ui/button'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { cardVariantsX } from "../../../../constants";
import { motion, useAnimation } from "framer-motion";
import { useInView } from 'react-intersection-observer';

const FeatureStep3 = () => {
  return (<div className='relative max-sm:pb-10'>
    <div className=' relative grid md:grid-cols-2 z-10 grid-cols-1 gap-10 items-center '>
      <div className='md:space-y-5 md:px-12 px-4'>
        {/* <p className='text-indigo-500'> Interac@ve Progress Tracking</p>
            <p className='text-indigo-500 md:text-7xl text-3xl font-semibold'>03</p> */}
        <p className='text-indigo-500 inline-block rounded-full text-[12px] bg-white p-2 px-3 '>Interactive Progress Tracking</p>
        <p className="text-3xl md:text-7xl font-semibold bg-gradient-to-t from-indigo-400 to-indigo-500 bg-clip-text text-transparent">
          03
        </p>
        <p className='md:text-3xl font-semibold text-2xl max-md:font-medium'>Update Task Completion Percentages in Real Time !</p>
        <p className='sm:text-sm secondary-textcolor '>Gain a clearer view of project advancement with real-time percentage updates.</p>
      </div>
      <div className='flex sm:justify-center items-end '>
        <div className='relative '>
          <img className="  md:w-full w-[50%] mx-auto  object-cover" loading="lazy" src="/assets/product/step3phone.png" alt="phone" />
          <div className="absolute top-[20%] max-sm:top-[-4%] sm:left-[-50%] left-[1%]">
            <SubFeatureStep1 />
          </div>
          <div className='sm:h-16 h-12 rounded-full xl:bottom-[-20px]  xl:left-[-12%] sm:bottom-[-30px]  sm:left-[-32%] bottom-[-30px]    sm:w-[450px] w-[300px] absolute transition-transform transform-origin-right transform rotate-[-10deg] z-10' style={{ background: "#EFF5FE" }}></div>
          <div className='sm:h-16 h-12 rounded-full xl:bottom-[11%]  xl:left-[-1%] sm:bottom-[9%]  sm:left-[-32%] bottom-[11%]  left-[5%]   sm:w-[450px] w-[300px] absolute transition-transform transform-origin-right transform rotate-[-10deg] -z-10' style={{ backgroundColor: "#ABC3F6" }}></div>
        </div>

      </div>
    </div>
    <div className='bg-gradient-to-t z-0 absolute  md:bottom-0 bottom-8 from-white to-indigo-50 p-4 rounded-3xl w-full h-full  md:h-[550px] lg:h-[500px]'></div>
  </div>
  )
}

export const FeatureStep3Home = () => {
  const navigate = useNavigate()
  return (<div className='relative '>
    <div className=' relative grid md:grid-cols-2 z-10 grid-cols-1 gap-10 items-center '>
      <div className='md:space-y-5 space-y-2 md:px-12 px-4'>
        <p className='text-indigo-500 inline-block rounded-full text-[12px] bg-white p-2 px-3 '>Interactive Progress Tracking</p>
        <p className="text-3xl md:text-7xl font-semibold bg-gradient-to-t from-indigo-400 to-indigo-500 bg-clip-text text-transparent">
          03
        </p>
        <p className='md:text-3xl font-semibold text-2xl max-md:font-medium'>Update Task Completion Percentages in Real Time !</p>
        <p className='sm:text-sm secondary-textcolor '>Gain a clearer view of project advancement with real-time percentage updates.</p>
        <Button onClick={() => navigate("/features")} className="buttonColor max-md:hidden  h-[50px] rounded-full hover:bg-indigo-700   px-8 md:text-lg md:font-medium md:py-6">
          Check All Features
        </Button>
      </div>
      <div className='flex sm:justify-center  items-end  '>
        <div className='relative'>
          <img className="  md:w-full w-[50%] mx-auto  object-cover" loading="lazy" src="/assets/product/step3phone.png" alt="phone" />
          <div className="absolute top-[20%] max-sm:top-[-4%] md:left-[-50%] sm:left-[-20%] left-[1%] ">
            <SubFeatureStep1 />
          </div>
          <div className='sm:h-16 h-12 rounded-full xl:bottom-[-20px]  xl:left-[-12%] sm:bottom-[-30px]  sm:left-[-32%] md:left-[-20%] bottom-[-30px]    sm:w-[450px] w-[300px] absolute transition-transform transform-origin-right transform rotate-[-10deg] z-10' style={{ background: "#EFF5FE" }}></div>
          <div className='sm:h-16 h-12 rounded-full xl:bottom-[11%]  xl:left-[-1%] sm:bottom-[9%]  sm:left-[-32%] md:left-[-20%] bottom-[11%]  left-[5%]   sm:w-[450px] w-[300px] absolute transition-transform transform-origin-right transform rotate-[-10deg] -z-10' style={{ backgroundColor: "#ABC3F6" }}></div>
        </div>
      </div>
      <div className='w-full justify-center flex mt-5 z-20 md:hidden '>
        <Button onClick={() => navigate("/features")} className="buttonColor  h-[50px] rounded-full hover:bg-indigo-700   px-8 md:text-lg md:font-medium ">
          Check All Features
        </Button>
      </div>
    </div>
    <div className='bg-gradient-to-t z-0 absolute  md:bottom-0 bottom-8 from-white to-indigo-50 p-4 rounded-3xl w-full h-full  md:h-[500px]'></div>

  </div>
  )
}


const SubFeatureStep1 = () => {
  const [percentage, setPercentage] = useState(0);
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.4 });

  useEffect(() => {
    if (inView) {
      const countUp = () => {
        let start = 0;
        const end = 60; // Final value
        const duration = 2000; // Duration of animation in milliseconds
        const incrementTime = duration / end; // Time between increments

        const interval = setInterval(() => {
          start += 1;
          setPercentage(start);

          if (start === end) {
            clearInterval(interval);
          }
        }, incrementTime);
      };

      countUp();

      controls.start("rotate");
    }
  }, [inView, controls]);


  const imageVariants1 = {
    rotate: {
      rotate: 360, // Full rotation
      transition: {
        duration: 4, // Duration for one full rotation
        ease: "linear", // Smooth rotation
        repeat: 0, // Complete one rotation and stop
      },
    },
  };


  return (
    <motion.div
      ref={ref}
      className='relative w-[250px] h-[250px]'
      initial="hidden"
      animate={controls}
      whileInView="visible"
      viewport={{ once: false, amount: 0.4 }}
    >
      <motion.div className='relative'>
        <img
          className="max-sm:w-[40%] w-[70%] relative max-h-full object-cover max-w-full"
          alt=""
          src="/assets/product/Frame1.svg"
        />
        <img
          className="absolute top-[13%] left-[28%] sm:top-[21%] sm:left-[48%] max-h-full object-cover max-w-full"
          alt=""
          src="/assets/product/Frame2.svg"
        />
        <motion.img
          variants={imageVariants1}
          animate="rotate"
          className="absolute md:top-[10%] md:left-[10%] top-[5%] left-[5%] max-h-full object-cover max-w-full md:max-w-[50%]"
          alt=""
          src="/assets/product/Frame3.svg"
        />
      </motion.div>
      <motion.img
        variants={cardVariantsX}
        className="bg-gradient-to-tl from-lime-300 to-lime-500 p-2 sm:rounded-xl rounded-md absolute top-[29%] left-[28%] sm:top-[52%] sm:left-[52%] max-sm:w-[13%] max-h-full object-cover max-w-full"
        alt=""
        src="/assets/product/Frame.svg"
      />
      <p className='absolute top-[8%] left-9 sm:top-[20%] sm:left-[58px] sm:text-3xl font-medium'>{percentage}%</p>
      <p className='absolute max-sm:top-[15%] max-sm:left-[10%] top-[32%] left-[23%] text-[10px]'>Completed</p>
    </motion.div>
  );
};


export default FeatureStep3
