import { Button } from '../../ui/button'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import StickySection from '../Features/Steps/StickySection'
import FeatureStep1 from '../Features/Steps/FeatureStep1'
import FeatureStep2 from '../Features/Steps/FeatureStep2'
import { FeatureStep3Home } from '../Features/Steps/FeatureStep3'

const HomeComponent4New = () => {
    return (
        <div className='wrapper md:mt-5'>
            <h1 className="font-semibold mt-2 md:text-[44px] md:leading-[1.2] text-xl text-center">
                These  <span style={{ color: "rgb(133, 196, 65)" }}> features</span> make us the top choice for you
            </h1>
            <p className='secondary-textcolor text-center my-3'>Experience the difference with our cutting-edge features that deliver unparalleled performance and innovation.</p>
            <>{/* <div className='space-y-32  md:block hidden'>
                <StickySection><FeatureStep1 /></StickySection>
                 <StickySection><FeatureStep2 /></StickySection>
                <StickySection><FeatureStep3Home /></StickySection> 
            </div> */}
                <div className='space-y-32 my-10 md:mb-16 max-md:mb-4 md:overflow-hidden'>
                    <FeatureStep1 />
                    <FeatureStep2 />
                    <FeatureStep3Home />
                </div>
            </>
        </div>
    )
}

const HomeComponent4 = () => {
    const navigate = useNavigate()
    return (
        <div className='wrapper md:mt-5'>
            <h1 className="font-semibold mt-2 md:text-5xl text-xl text-center">
                These  <span style={{ color: "rgb(133, 196, 65)" }}> features</span> make us the top choice for you
            </h1>
            <p className='secondary-textcolor text-center my-3'>Experience the difference with our cutting-edge features that deliver unparalleled performance and innovation.</p>

            <div className="grid md:grid-cols-2 items-center my-8 grid-cols-1 gap-6">
                <div className='space-y-3 max-md:text-center'>
                    <div className='flex gap-4 max-md:justify-center items-center'>
                        <img className="h-[50px] w-[50px] relative rounded-21xl overflow-hidden shrink-0" loading="lazy" alt="" src="/assets/home/frame-5234.svg" />
                        <p className='text-lg'>Create Simple Tasks</p>
                    </div>
                    <p className='md:text-2xl text-lg font-semibold'>Effortlessly Create and Delegate Tasks to Yourself and Others!</p>
                    <p className='secondary-textcolor'>Quickly create tasks that can be independent or linked to projects, adapting to your needs.</p>
                    <Button onClick={() => navigate("/features")} className="buttonColor rounded-full hover:bg-indigo-700  md:px-12 px-8 md:text-lg md:font-medium md:py-6">
                        Check All Features
                    </Button>
                </div>
                <div className='w-full mx-auto relative overflow-hidden'>
                    <img className=" h-full  md:w-[350px] w-[70%] mx-auto  object-cover" loading="lazy" alt="" src="/assets/home/iphone-15-pro-portrait-mockup-3@2x.png" />
                    <img className="md:w-[344px] w-[70%] flex-1 absolute top-20  max-h-full object-cover max-w-full" alt="" src="/assets/home/task1.png" />
                    <img className="md:w-[322px] w-[70%] md:h-32 max-h-full absolute object-cover left-3 md:left-5 lg:left-16 top-44 sm:top-60 max-w-full" alt="" src="/assets/home/task2.png" />
                    <img className="md:h-32 md:w-80  w-[70%] absolute lg:left-28 md:left-12 left-5 sm:top-96  top-[270px] object-cover max-w-full" alt="" src="/assets/home/task3.png" />
                    <div className='h-20 rounded-full top-[500px] md:left-10 left-0 sm:w-[500px] w-[400px] absolute transition-transform transform-origin-right transform rotate-[-30deg] -z-10' style={{ background: "rgb(133, 196, 65)" }}></div>
                    <div className='h-20 rounded-full top-[280px] md:left-52  left-0 sm:w-[500px] w-[400px] absolute transition-transform transform-origin-right transform rotate-[-30deg] -z-10' style={{ backgroundColor: "#ABC3F6" }}></div>
                    <div className='h-20 rounded-full top-[150px] md:left-10 left-0  sm:w-[450px] w-[400px] bg-green-100 absolute transition-transform transform-origin-right transform rotate-[-30deg] -z-10'></div>

                </div>
            </div>

        </div>


    )
}

export default HomeComponent4New