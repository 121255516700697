import { Input } from "../../../components/ui/input"
import { Button } from "../../ui/button"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../components/ui/form"
import { useState } from "react"
import { Link } from "react-router-dom"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AboutComponent4 = () => {

  return (<div className='wrapper my-6 '>
    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-6 rounded-3xl border-gray-100 border">
      <div className="space-y-6 bg-gradient-to-tl relative md:rounded-l-3xl max-md:rounded-t-3xl from-indigo-300 to-indigo-500 text-white p-7 md:p-10 flex flex-col justify-between">
        <h1 className="relative  gap-6 text-2xl  md:text-[44px] md:leading-[1.2] font-medium md:font-semibold z-10 md:max-w-[330px]">We would love to hear from you</h1>
        {/*  <div className="relative md:text-[44px] md:leading-[1.2] max-md:hidden font-semibold z-10">
          <p>We would love</p>
          <p>hear</p>
          <p>from you</p>
        </div> */}
        <div className="justify-end space-y-6 relative z-10 ">
          {/* <div className="flex gap-4 items-center">
            <div className="w-12 h-12 bg-white rounded-full justify-center flex items-center">
              <img src="/assets/about/phone.svg" alt="phone" />
            </div>
            <p>+91 8765432342</p>
          </div> */}
          <div className="flex gap-4 items-center">
            <div className="w-12 h-12 bg-white rounded-full justify-center flex items-center">
              <img src="/assets/about/envelope.svg" alt="envelope" />
            </div>
            <Link to="mailto:hello@assignit.in" className="hover:underline">
              hello@assignit.in
            </Link>
           {/*  <p>hello@assignit.com</p> */}
          </div>
        </div>
        <img src="/assets/about/submit1.svg" alt="submit1" className="absolute md:right-10  z-0 md:bottom-20 bottom-12 right-7 md:w-[68px] w-10" />
        <img src="/assets/about/submit2.svg" alt="submit2" className="absolute md:right-10  z-0 right-6 bottom-0 md:h-12 h-8 " />
        <img src="/assets/about/submit3.svg" alt="submit3" className="absolute right-0  z-0 md:bottom-8 bottom-3 md:w-12 w-8 " />
      </div>
      <div className=" p-5 py-6 md:p-10 space-y-6">
        <p className="text-[32px]  font-medium">Submit a Request</p>
        <FormSubmit />
        {/* <div className="space-y-3 max-sm:space-y-5">
          <Input className='focus:border-2 h-[50px] max-w-[550px] text-black focus:border-gray-500 rounded-xl focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent' placeholder='Your Name*' />
          <Input className='focus:border-2 h-[50px] max-w-[550px] text-black focus:border-gray-500 rounded-xl focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent' placeholder='Email Address*' />
          <Input className='focus:border-2 h-[50px] max-w-[550px] text-black focus:border-gray-500 rounded-xl focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent' placeholder='Your Message*' />

        </div>
        <Button className="buttonColor rounded-full max-md:hidden  hover:bg-indigo-700  md:px-12 px-8 md:text-lg md:font-medium md:py-6">
          Send Message
        </Button>
        <Button className="buttonColor w-full h-[50px] rounded-full md:hidden mb-6 max-w-[540px]  hover:bg-indigo-700   px-6 md:text-lg md:font-medium md:py-6">
          Send Message
        </Button> */}
      </div>

    </div>

  </div>
  )
}

const formSchema = z.object({
  email: z.string()
    .email({ message: "Invalid email address" }) // Validates email format
    .min(2, { message: "Email must be at least 2 characters long" })
    .max(50, { message: "Email must be at most 50 characters long" }),

  name: z.string()
    .min(3, { message: "Name must be at least 3 characters long" }) // Ensures name length
    .max(50, { message: "Name must be at most 50 characters long" }),

  message: z.string()
    .min(5, { message: "Message must be at least 5 characters long" }) // Ensures message length
    .max(500, { message: "Message must be at most 500 characters long" }),
});


const FormSubmit = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      message: "",
      name: ""
    },
  })

  const { reset } = form;

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setLoading(true);
    const formUrl = 'https://script.google.com/macros/s/AKfycbwwqyu9FCT3ipZ0EMmBOp2MqpAWv_kmxq65C0vh_Vw7Cz2cqGkPOUftz5zmMybCDcnY/exec';
    try {
      const response = await fetch(formUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          'name': values.name,
          'email': values.email,
          'message': values.message
        }).toString()
      });
      reset()
      if (response.ok) {
          toast.success('Message sent successfully!', {
            autoClose: 2000,
            style: {
              background: 'linear-gradient(to right, #93eb99, #7cef31)', // Lime gradient
              color: '#ffffff',
            },
          }); 
      }
    } catch (error) {
      toast.error('Failed to send Message.', {
        autoClose: 2000,
        style: {
          background: 'linear-gradient(to right, #FF0000, #DC143C)', // Red gradient
          color: '#ffffff',
        },
      });
    } finally {
      setLoading(false); // Set loading to false when submission ends
    }
  }
  return <Form {...form}>
    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5 max-sm:space-y-5">
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input {...field} className='focus:border-2 h-[50px] max-w-[550px] text-black focus:border-gray-500 rounded-xl focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent' placeholder='Your Name*' />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input {...field} className='focus:border-2 h-[50px] max-w-[550px] text-black focus:border-gray-500 rounded-xl focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent' placeholder='Email Address*' />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="message"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input {...field} className='focus:border-2 h-[50px] max-w-[550px] text-black focus:border-gray-500 rounded-xl focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent' placeholder='Your Message*' />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <Button type="submit" className="buttonColor rounded-full max-md:hidden  hover:bg-indigo-700  md:px-12 px-8 md:text-lg md:font-medium md:py-6" disabled={loading}>
        {loading ? 'Sending Message...' : 'Send Message'}
      </Button>
      <Button type="submit" className="buttonColor w-full h-[50px] rounded-full md:hidden mb-6 max-w-[540px]  hover:bg-indigo-700   px-6 md:text-lg md:font-medium md:py-6" disabled={loading}>
        {loading ? 'Sending Message...' : 'Send Message'}
      </Button>
    </form>
  </Form>
}
export default AboutComponent4