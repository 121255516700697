import { Route,RouterProvider,createBrowserRouter,createRoutesFromElements, } from "react-router-dom";
import Home from "./Pages/Home";
import MainNavigation from "./Pages/MainNavigation";
import { ScrollTop } from "./components/shared/RootLayout/ScrollToTop";
import SubNavigation from "./Pages/SubNavigation";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";
import Features from "./Pages/Features";


// const Rout = createBrowserRouter(
//   createRoutesFromElements(
//     <Route>
//       <Route path="/" element={<MainNavigation />}>
//         <Route index element={<Home />} />
//       </Route>
//       <Route path="/"  element={<SubNavigation />}>
//       <Route element={<ScrollTop />}>
//         <Route path="contactus" element={<ContactUs />} />
//         <Route path="hrconsulting" element={<HRConsulting />} />
//         <Route path="hroperational" element={<HROperational />} />
//         <Route path="hrcompliance" element={<HRCompliance />} />
//         <Route path="hrpolicy" element={<HRPolicy />} />
//         <Route path="performancemanagement" element={<HRPerformance />} />
//         <Route path="recruitingservices" element={<RecruitingServices />} />
//         <Route path="about" element={<About />} />
//       </Route>
//       </Route>
//     </Route>
//   )
// );
const Rout = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainNavigation />}>
      <Route element={<ScrollTop />}>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
       {/*  <Route path="blogs" element={<Blog />} /> */}
        <Route path="features" element={<Features />} />
      </Route>
    </Route>
  )
);


const App = () => {
  return <RouterProvider router={Rout} />;
}; 


export default App;
