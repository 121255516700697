import { useEffect, useRef } from 'react';
import { Card } from '../../../components/ui/card'
import { motion } from "framer-motion";
import { cardVariantsX,  cardVariantsY, imageVariants, smoothScrollVariants } from '../../../constants';

// const HomeComponent3 = () => {

//     return (
//         <div className="bg-gray-100 relative xl:mt-[350px] py-4 md:mt-[400px] lg:mt-[300px] mt-[520px] ">
//             <div className='wrapper'>
//                 <div className='grid md:grid-cols-12  grid-cols-1 md:gap-8 gap-6'>
//                     <div className='flex flex-col gap-5 md:col-span-6 lg:col-span-8'>
//                         <p className='text-xl md:text-[44px] md:leading-[1.2] font-semibold max-md:text-center'>In a world full of productivity tools, what makes
//                             <span style={{ color: "rgb(133, 196, 65)" }}> AssignIt</span> stand out? </p>
//                         <p className='secondary-textcolor  max-md:text-center'>Here’s why AssignIt is the perfect choice for managing your tasks and projects</p>
//                     </div>
//                     <div className='md:col-span-6  lg:col-span-4 relative overflow-hidden'>
//                         <div className='w-full relative h-[210px]  max-md:h-[180px] md:rounded-[32px] rounded-3xl bg-gradient-to-tl from-indigo-100 to-indigo-500 p-4 max-md:px-3'>
//                             <div className='w-[60%] pl-3 pt-2 text-white'>
//                                 <p className='md:text-3xl text-2xl font-medium'>User-Centric</p>
//                                 <p className='md:text-3xl text-2xl font-medium'>Design</p>
//                                 <p className='my-2 max-md:hidden text-sm'>AssignIt is designed for simplicity without sacrificing functionality.</p>
//                             </div>
//                             <div className="absolute right-0 bottom-0">
//                             <UserCentricEcllipse />
//                         </div>
//                         </div>

//                         {/* <div className='absolute bottom-0  right-0 '>
//                             <div className='relative flex justify-center items-center'>
//                                 <img className="p-2 w-16 h-16" alt="" src="/assets/home/group-29.svg" />

//                                 <div className='absolute w-80 h-80 rounded-full border-2 border-gray-100 top-[-120%] right-[-220%]' >
//                                     <img className="h-10 w-10 p-2 bg-white rounded-full absolute mt-[-10px] ml-[100px]" alt="" src="/assets/home/group.svg" />
//                                 </div>

//                                 <div className='absolute w-60 h-60 rounded-full border-2 border-gray-100 top-[-70%] right-[-150%]' >
//                                     <img className="h-10 w-10 p-2 bg-white rounded-full absolute  mt-[40px]" alt="" src="/assets/home/frame-3.svg" />
//                                 </div>

//                                 <div className='absolute w-40 h-40 rounded-full border-2 border-gray-100 top-[-20%] right-[-90%]' >
//                                     <img className="h-10 w-10 p-2 bg-white rounded-full absolute left-1/2 transform -translate-x-1/2 mt-[-24px]" alt="" src="/assets/home/group.svg" />
//                                 </div>
//                             </div>
//                         </div> */}
//                     </div>
//                 </div>
//                 <div className='grid md:grid-cols-12  grid-cols-1 my-4 gap-6'>
//                     <Card className='max-md:flex  md:rounded-[32px]  max-md:h-[180px] rounded-3xl flex-col relative bg-gradient-to-r border-none p-3 py-4 from-white to-green-50 md:col-span-5'>
//                         <div className='w-[60%] flex flex-col gap-3 pl-3 py-4'>
//                             <p className='md:text-3xl font-medium text-2xl'>Flexibility and Adaptability</p>
//                             <p className='secondary-textcolor text-sm max-md:hidden'>Whether for personal tasks or team projects, AssignIt adapts to your needs with customizable features and intuitive controls</p>
//                         </div>
//                         <img className="absolute right-0 bottom-0 overflow-hidden shrink-0 max-md:w-36" alt="" src="/assets/home/frame-4.svg" />
//                     </Card>

//                     <Card className='max-md:flex  md:rounded-[32px]  max-md:h-[180px] rounded-3xl flex-col overflow-hidden relative border-none p-3 py-4 md:col-span-7'>
//                         <div className='md:w-[60%] w-[80%] flex flex-col gap-3 z-10 relative pl-3 py-4'>
//                             <p className='md:text-3xl md:w-[90%] font-medium text-2xl'>Visual Insights at a Glance</p>
//                             <p className='secondary-textcolor text-sm max-md:hidden'>Our dashboard offers real-time insights into your projects. Whether you need a quick update or a detailed analysis, AssignIt provides the information you need to stay on top of your tasks.</p>
//                         </div>
//                         <img className="absolute max-md:bottom-[10%] bottom-[20%] right-[5%] flex justify-end items-end overflow-hidden z-[1] h-auto max-md:w-28  max-md:h-28 " alt="" src="/assets/home/frame-5.svg" />
//                         <img className="absolute top-10 right-10  overflow-hidden z-0 h-auto max-md:w-[50%] " alt="" src="/assets/home/frame111.svg" />
//                     </Card>

//                 </div>
//                 <div className='grid md:grid-cols-12  grid-cols-1 my-4 gap-6'>
//                     <Card className='max-md:hidden overflow-hidden flex-col max-md:h-[180px] relative  md:rounded-[32px] rounded-3xl  border-none p-3 md:py-4 md:col-span-7'>
//                         <div className='w-[60%] md:min-h-[190px] flex flex-col gap-3 pl-3 py-4'>
//                             <p className='md:text-3xl font-medium text-2xl'>Seamless Collaboration</p>
//                             <p className='secondary-textcolor text-sm max-md:hidden'>AssignIt simplifies teamwork by letting you delegate tasks, track progress, and communicate in real-time—all in one platform. It keeps your team connected, whether they're in the office or worldwide.</p>
//                         </div>
//                         <div className='absolute bottom-[8%] max-sm:bottom-[0%] right-[10%] max-sm:right-[15%]'>
//                             <SubHomeComponent3 />
//                         </div>
//                     </Card>

//                     <Card className='max-md:flex md:rounded-[32px] rounded-3xl text-white flex-col relative border-none max-md:h-[180px] bg-gradient-to-tl from-indigo-100 to-indigo-500 p-4 py-4 md:col-span-5'>
//                         <div className='md:w-[70%] max-sm:w-[60%] flex flex-col gap-3 pl-3 py-4'>
//                             <p className='md:text-3xl font-medium text-2xl md:w-[70%]'>No Account? No Problem!</p>
//                             <p className=' max-md:hidden text-sm'>AssignIt lets you assign tasks to non-users with a simple link—no signup needed.</p>
//                         </div>
//                     </Card>

//                     <Card className='max-md:flex md:hidden overflow-hidden flex-col max-md:h-[180px] relative  md:rounded-[32px] rounded-3xl  border-none p-3 md:py-4 md:col-span-7'>
//                         <div className='w-[60%] md:min-h-[200px] flex flex-col gap-3 pl-3 py-4'>
//                             <p className='md:text-3xl font-medium text-2xl'>Seamless Collaboration</p>
//                             <p className='secondary-textcolor text-sm max-md:hidden '>AssignIt simplifies teamwork by letting you delegate tasks, track progress, and communicate in real-time—all in one platform. It keeps your team connected, whether they're in the office or worldwide.</p>
//                         </div>
//                         <div className='absolute bottom-[10%] max-sm:bottom-[0%] right-[10%] max-sm:right-[15%]'>
//                             <SubHomeComponent3 />
//                         </div>
//                     </Card>
//                 </div>
//             </div>
//         </div>
//     )
// }

const UserCentricEcllipse = () => {
    return (
        <div className="relative w-[150px] h-[150px]">
            {/* Main Icon */}
            <img
                className="p-2 w-16 h-16 absolute right-0 bottom-0"
                alt="Main Icon"
                src="/assets/home/group-29.svg"
            />

            {/* Outer Large Circle */}
            <div className="absolute w-60 h-60 rounded-full border-2 border-gray-100 right-[-60%] bottom-[-60%]">
                <img
                    className="h-10 w-10 p-2 bg-white rounded-full absolute top-[-10%] left-1/2 transform -translate-x-1/2"
                    alt="Outer Circle Icon"
                    src="/assets/home/group.svg"
                />
            </div>

            {/* Middle Circle */}
            <div className="absolute w-48 h-48 rounded-full border-2 border-gray-100 right-[-45%] bottom-[-45%]">
                <img
                    className="h-10 w-10 p-2 bg-white rounded-full absolute top-[30%] left-[2%] transform -translate-x-1/2"
                    alt="Middle Circle Icon"
                    src="/assets/home/frame-3.svg"
                />
            </div>

            {/* Inner Small Circle */}
            <div className="absolute w-40 h-40 rounded-full border-2 border-gray-100 right-[-40%] bottom-[-40%]">
                <img
                    className="h-10 w-10 p-2 bg-white rounded-full absolute top-[0%] left-[18%] transform -translate-x-1/2"
                    alt="Inner Circle Icon"
                    src="/assets/home/group.svg"
                />
            </div>
        </div>
    );
};

// const SubHomeComponent3 = () => {
//     return (
//         <div className="relative w-[150px] h-[150px] max-md:h-28 max-md:w-28">
//             <img className="h-16 w-16 max-md:w-12 max-md:h-12 absolute top-[-15%] right-[5%] object-cover rounded-full" loading="lazy" alt="" src="/assets/home/ellipse-16@2x.png" />
//             <img className="h-16 w-16 max-md:w-12 max-md:h-12 absolute bottom-[20%] right-[35%] object-cover rounded-full" alt="" src="/assets/home/ellipse-15@2x.png" />
//             <img className="h-16 w-16 max-md:w-12 max-md:h-12 absolute bottom-[20%] right-[-25%] object-cover rounded-full" loading="lazy" alt="" src="/assets/home/ellipse-17@2x.png" />
//             <div className="h-14 w-14 max-md:w-10 max-md:h-10   absolute bottom-[40%] right-[8%]  rounded-full bg-indigo-600 flex flex-row items-center justify-center p-2 box-border z-[1]">
//                 <img className="h-8 w-8 relative overflow-hidden shrink-0" alt="" src="/assets/home/heroiconssolidplus.svg" /></div>
//             <img className="h-[25px] w-[25px] max-md:h-[15px] max-md:w-[15px] absolute max-md:top-[18%] max-md:left-[40%] top-[13%] left-[30%]" loading="lazy" alt="" src="/assets/home/vector-626.svg" />
//             <img className="w-[25px] h-[25px] max-md:h-[15px] max-md:w-[15px] absolute max-md:top-[18%] max-md:right-[-8%]  top-[13%] right-[-15%] object-contain" loading="lazy" alt="" src="/assets/home/vector-627.svg" />

//         </div>)
// }

const HomeComponent3 = () => {
    const cardVariantsXN = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.6, ease: "easeInOut" } },
        exit: { opacity: 0, x: 50, transition: { duration: 0.6, ease: "easeInOut" } },
      };

    return (
        <div className="bg-gray-100 relative xl:mt-[350px] py-4 md:mt-[400px] lg:mt-[300px] mt-[520px] ">
            <div className='wrapper'>
                <motion.div  initial="hidden"  viewport={{ once: false, amount: 0.4 }} transition={{ staggerChildren: 0.3 }} whileInView="visible"  className='grid md:grid-cols-12 max-md:hidden grid-cols-1 md:gap-8 gap-6 max-sm:mb-6'>
                    <motion.div variants={smoothScrollVariants} className='max-sm:hidden flex flex-col gap-5 md:col-span-6 lg:col-span-8'>
                        <p className='text-xl md:text-[44px] md:leading-[1.2] font-semibold max-md:text-center'>In a world full of productivity tools, what makes
                            <span style={{ color: "rgb(133, 196, 65)" }}> AssignIt</span> stand out? </p>
                        <p className='secondary-textcolor  max-md:text-center'>Here’s why AssignIt is the perfect choice for managing your tasks and projects</p>
                    </motion.div>
                    <motion.div variants={cardVariantsY} className='sm:hidden flex flex-col gap-5 md:col-span-6 lg:col-span-8'>
                        <p className='text-xl md:text-[44px] md:leading-[1.2] font-semibold max-md:text-center'>In a world full of productivity tools, what makes
                            <span style={{ color: "rgb(133, 196, 65)" }}> AssignIt</span> stand out? </p>
                        <p className='secondary-textcolor  max-md:text-center'>Here’s why AssignIt is the perfect choice for managing your tasks and projects</p>
                    </motion.div>
                    <motion.div variants={cardVariantsY} className='sm:hidden md:col-span-6  lg:col-span-4 relative overflow-hidden'>
                        <div className='w-full relative h-[210px]  max-md:h-[180px] md:rounded-[32px] rounded-3xl bg-gradient-to-tl from-indigo-100 to-indigo-500 p-4 max-md:px-3'>
                            <div className='w-[60%] pl-3 pt-2 text-white'>
                                <p className='md:text-3xl text-2xl font-medium'>User-Centric</p>
                                <p className='md:text-3xl text-2xl font-medium'>Design</p>
                                <p className='my-2 max-md:hidden text-sm'>AssignIt is designed for simplicity without sacrificing functionality.</p>
                            </div>
                            <div className="absolute right-0 bottom-0">
                            <UserCentricEcllipse />
                        </div>
                        </div>
                    </motion.div>
                    <motion.div variants={cardVariantsXN} className='max-sm:hidden  md:col-span-6  lg:col-span-4 relative overflow-hidden'>
                        <div className='w-full relative h-[210px]  max-md:h-[180px] md:rounded-[32px] rounded-3xl bg-gradient-to-tl from-indigo-100 to-indigo-500 p-4 max-md:px-3'>
                            <div className='w-[60%] pl-3 pt-2 text-white'>
                                <p className='md:text-3xl text-2xl font-medium'>User-Centric</p>
                                <p className='md:text-3xl text-2xl font-medium'>Design</p>
                                <p className='my-2 max-md:hidden text-sm'>AssignIt is designed for simplicity without sacrificing functionality.</p>
                            </div>
                            <div className="absolute right-0 bottom-0">
                            <UserCentricEcllipse />
                        </div>
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div  initial="hidden"  viewport={{ once: false, amount: 0.2 }} transition={{ staggerChildren: 0.1 }} whileInView="visible"  className='grid md:grid-cols-12 md:hidden grid-cols-1 md:gap-8 gap-6 max-sm:mb-6'>
                    <motion.div variants={smoothScrollVariants} className='max-sm:hidden flex flex-col gap-5 md:col-span-6 lg:col-span-8'>
                        <p className='text-xl md:text-[44px] md:leading-[1.2] font-semibold max-md:text-center'>In a world full of productivity tools, what makes
                            <span style={{ color: "rgb(133, 196, 65)" }}> AssignIt</span> stand out? </p>
                        <p className='secondary-textcolor  max-md:text-center'>Here’s why AssignIt is the perfect choice for managing your tasks and projects</p>
                    </motion.div>
                    <motion.div variants={cardVariantsY} className='sm:hidden flex flex-col gap-5 md:col-span-6 lg:col-span-8'>
                        <p className='text-xl md:text-[44px] md:leading-[1.2] font-semibold max-md:text-center'>In a world full of productivity tools, what makes
                            <span style={{ color: "rgb(133, 196, 65)" }}> AssignIt</span> stand out? </p>
                        <p className='secondary-textcolor  max-md:text-center'>Here’s why AssignIt is the perfect choice for managing your tasks and projects</p>
                    </motion.div>
                    <motion.div variants={cardVariantsY} className='sm:hidden md:col-span-6  lg:col-span-4 relative overflow-hidden'>
                        <div className='w-full relative h-[210px]  max-md:h-[180px] md:rounded-[32px] rounded-3xl bg-gradient-to-tl from-indigo-100 to-indigo-500 p-4 max-md:px-3'>
                            <div className='w-[60%] pl-3 pt-2 text-white'>
                                <p className='md:text-3xl text-2xl font-medium'>User-Centric</p>
                                <p className='md:text-3xl text-2xl font-medium'>Design</p>
                                <p className='my-2 max-md:hidden text-sm'>AssignIt is designed for simplicity without sacrificing functionality.</p>
                            </div>
                            <div className="absolute right-0 bottom-0">
                            <UserCentricEcllipse />
                        </div>
                        </div>
                    </motion.div>
                    <motion.div variants={cardVariantsX} className='max-sm:hidden  md:col-span-6  lg:col-span-4 relative overflow-hidden'>
                        <div className='w-full relative h-[210px]  max-md:h-[180px] md:rounded-[32px] rounded-3xl bg-gradient-to-tl from-indigo-100 to-indigo-500 p-4 max-md:px-3'>
                            <div className='w-[60%] pl-3 pt-2 text-white'>
                                <p className='md:text-3xl text-2xl font-medium'>User-Centric</p>
                                <p className='md:text-3xl text-2xl font-medium'>Design</p>
                                <p className='my-2 max-md:hidden text-sm'>AssignIt is designed for simplicity without sacrificing functionality.</p>
                            </div>
                            <div className="absolute right-0 bottom-0">
                            <UserCentricEcllipse />
                        </div>
                        </div>

                        {/* <div className='absolute bottom-0  right-0 '>
                            <div className='relative flex justify-center items-center'>
                                <img className="p-2 w-16 h-16" alt="" src="/assets/home/group-29.svg" />

                                <div className='absolute w-80 h-80 rounded-full border-2 border-gray-100 top-[-120%] right-[-220%]' >
                                    <img className="h-10 w-10 p-2 bg-white rounded-full absolute mt-[-10px] ml-[100px]" alt="" src="/assets/home/group.svg" />
                                </div>

                                <div className='absolute w-60 h-60 rounded-full border-2 border-gray-100 top-[-70%] right-[-150%]' >
                                    <img className="h-10 w-10 p-2 bg-white rounded-full absolute  mt-[40px]" alt="" src="/assets/home/frame-3.svg" />
                                </div>

                                <div className='absolute w-40 h-40 rounded-full border-2 border-gray-100 top-[-20%] right-[-90%]' >
                                    <img className="h-10 w-10 p-2 bg-white rounded-full absolute left-1/2 transform -translate-x-1/2 mt-[-24px]" alt="" src="/assets/home/group.svg" />
                                </div>
                            </div>
                        </div> */}
                    </motion.div>
                </motion.div>
                <motion.div  initial="hidden"  viewport={{ once: false, amount: 0.5 }} transition={{ staggerChildren: 0.4 }} whileInView="visible"   className='grid md:grid-cols-12 max-md:hidden  grid-cols-1 my-4 max-sm:my-6 gap-6'>
                    <motion.div variants={cardVariantsY} className='max-md:flex  md:rounded-[32px]  max-md:h-[180px] rounded-3xl flex-col relative bg-gradient-to-r border-none p-3 py-4 from-white to-green-50 md:col-span-5'>
                        <div className='w-[60%] flex flex-col gap-3 pl-3 py-4'>
                            <p className='md:text-3xl font-medium text-2xl'>Flexibility and Adaptability</p>
                            <p className='secondary-textcolor text-sm max-md:hidden'>Whether for personal tasks or team projects, AssignIt adapts to your needs with customizable features and intuitive controls</p>
                        </div>
                        <img className="absolute right-0 bottom-0 overflow-hidden shrink-0 max-md:w-36" alt="" src="/assets/home/frame-4.svg" />
                    </motion.div>

                    <motion.div variants={cardVariantsY} className='max-md:flex bg-white  md:rounded-[32px]  max-md:h-[180px] rounded-3xl flex-col overflow-hidden relative border-none p-3 py-4 md:col-span-7'>
                        <div className='md:w-[60%] w-[80%] flex flex-col gap-3 z-10 relative pl-3 py-4'>
                            <p className='md:text-3xl md:w-[90%] font-medium text-2xl'>Visual Insights at a Glance</p>
                            <p className='secondary-textcolor text-sm max-md:hidden'>Our dashboard offers real-time insights into your projects. Whether you need a quick update or a detailed analysis, AssignIt provides the information you need to stay on top of your tasks.</p>
                        </div>
                        <img className="absolute max-md:bottom-[10%] bottom-[20%] right-[5%] flex justify-end items-end overflow-hidden z-[1] h-auto max-md:w-28  max-md:h-28 " alt="" src="/assets/home/frame-5.svg" />
                        <img className="absolute top-10 right-10  overflow-hidden z-0 h-auto max-md:w-[50%] " alt="" src="/assets/home/frame111.svg" />
                    </motion.div>

                </motion.div>
                <motion.div  initial="hidden"  viewport={{ once: false, amount: 0.5 }} transition={{ staggerChildren: 0.4 }} whileInView="visible"  className='grid md:grid-cols-12 max-md:hidden  grid-cols-1 my-4 gap-6 max-sm:my-6'>
                    <motion.div variants={cardVariantsY} className='max-md:hidden bg-white overflow-hidden flex-col max-md:h-[180px] relative  md:rounded-[32px] rounded-3xl  border-none p-3 md:py-4 md:col-span-7'>
                        <div className='w-[60%] md:min-h-[190px] flex flex-col gap-3 pl-3 py-4'>
                            <p className='md:text-3xl font-medium text-2xl'>Seamless Collaboration</p>
                            <p className='secondary-textcolor text-sm max-md:hidden'>AssignIt simplifies teamwork by letting you delegate tasks, track progress, and communicate in real-time—all in one platform. It keeps your team connected, whether they're in the office or worldwide.</p>
                        </div>
                        <div className='absolute bottom-[8%] max-sm:bottom-[0%] right-[10%] max-sm:right-[15%]'>
                            <SubHomeComponent3 />
                        </div>
                    </motion.div>

                    <motion.div variants={cardVariantsY} className='max-md:flex md:rounded-[32px] rounded-3xl text-white flex-col relative border-none max-md:h-[180px] bg-gradient-to-tl from-indigo-100 to-indigo-500 p-4 py-4 md:col-span-5'>
                        <div className='md:w-[70%] max-sm:w-[60%] flex flex-col gap-3 pl-3 py-4'>
                            <p className='md:text-3xl font-medium text-2xl md:w-[70%]'>No Account? No Problem!</p>
                            <p className=' max-md:hidden text-sm'>AssignIt lets you assign tasks to non-users with a simple link—no signup needed.</p>
                        </div>
                    </motion.div>

                    <motion.div variants={cardVariantsY} className='max-md:flex bg-white md:hidden overflow-hidden flex-col max-md:h-[180px] relative  md:rounded-[32px] rounded-3xl  border-none p-3 md:py-4 md:col-span-7'>
                        <div className='w-[60%] md:min-h-[200px] flex flex-col gap-3 pl-3 py-4'>
                            <p className='md:text-3xl font-medium text-2xl'>Seamless Collaboration</p>
                            <p className='secondary-textcolor text-sm max-md:hidden '>AssignIt simplifies teamwork by letting you delegate tasks, track progress, and communicate in real-time—all in one platform. It keeps your team connected, whether they're in the office or worldwide.</p>
                        </div>
                        <div className='absolute bottom-[10%] max-sm:bottom-[0%] right-[10%] max-sm:right-[15%]'>
                            <SubHomeComponent3 />
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div  initial="hidden"  viewport={{ once: false, amount: 0.2 }} transition={{ staggerChildren: 0.1 }} whileInView="visible"   className='grid md:grid-cols-12 md:hidden grid-cols-1 my-4 max-sm:my-6 gap-6'>
                    <motion.div variants={cardVariantsY} className='max-md:flex  md:rounded-[32px]  max-md:h-[180px] rounded-3xl flex-col relative bg-gradient-to-r border-none p-3 py-4 from-white to-green-50 md:col-span-5'>
                        <div className='w-[60%] flex flex-col gap-3 pl-3 py-4'>
                            <p className='md:text-3xl font-medium text-2xl'>Flexibility and Adaptability</p>
                            <p className='secondary-textcolor text-sm max-md:hidden'>Whether for personal tasks or team projects, AssignIt adapts to your needs with customizable features and intuitive controls</p>
                        </div>
                        <img className="absolute right-0 bottom-0 overflow-hidden shrink-0 max-md:w-36" alt="" src="/assets/home/frame-4.svg" />
                    </motion.div>

                    <motion.div variants={cardVariantsY} className='max-md:flex bg-white  md:rounded-[32px]  max-md:h-[180px] rounded-3xl flex-col overflow-hidden relative border-none p-3 py-4 md:col-span-7'>
                        <div className='md:w-[60%] w-[80%] flex flex-col gap-3 z-10 relative pl-3 py-4'>
                            <p className='md:text-3xl md:w-[90%] font-medium text-2xl'>Visual Insights at a Glance</p>
                            <p className='secondary-textcolor text-sm max-md:hidden'>Our dashboard offers real-time insights into your projects. Whether you need a quick update or a detailed analysis, AssignIt provides the information you need to stay on top of your tasks.</p>
                        </div>
                        <img className="absolute max-md:bottom-[10%] bottom-[20%] right-[5%] flex justify-end items-end overflow-hidden z-[1] h-auto max-md:w-28  max-md:h-28 " alt="" src="/assets/home/frame-5.svg" />
                        <img className="absolute top-10 right-10  overflow-hidden z-0 h-auto max-md:w-[50%] " alt="" src="/assets/home/frame111.svg" />
                    </motion.div>

                </motion.div>
                <motion.div  initial="hidden"  viewport={{ once: false, amount: 0.2 }} transition={{ staggerChildren: 0.1 }} whileInView="visible"  className='grid md:grid-cols-12 md:hidden grid-cols-1 my-4 gap-6 max-sm:my-6'>
                    <motion.div variants={cardVariantsY} className='max-md:hidden bg-white overflow-hidden flex-col max-md:h-[180px] relative  md:rounded-[32px] rounded-3xl  border-none p-3 md:py-4 md:col-span-7'>
                        <div className='w-[60%] md:min-h-[190px] flex flex-col gap-3 pl-3 py-4'>
                            <p className='md:text-3xl font-medium text-2xl'>Seamless Collaboration</p>
                            <p className='secondary-textcolor text-sm max-md:hidden'>AssignIt simplifies teamwork by letting you delegate tasks, track progress, and communicate in real-time—all in one platform. It keeps your team connected, whether they're in the office or worldwide.</p>
                        </div>
                        <div className='absolute bottom-[8%] max-sm:bottom-[0%] right-[10%] max-sm:right-[15%]'>
                            <SubHomeComponent3 />
                        </div>
                    </motion.div>

                    <motion.div variants={cardVariantsY} className='max-md:flex md:rounded-[32px] rounded-3xl text-white flex-col relative border-none max-md:h-[180px] bg-gradient-to-tl from-indigo-100 to-indigo-500 p-4 py-4 md:col-span-5'>
                        <div className='md:w-[70%] max-sm:w-[60%] flex flex-col gap-3 pl-3 py-4'>
                            <p className='md:text-3xl font-medium text-2xl md:w-[70%]'>No Account? No Problem!</p>
                            <p className=' max-md:hidden text-sm'>AssignIt lets you assign tasks to non-users with a simple link—no signup needed.</p>
                        </div>
                    </motion.div>

                    <motion.div variants={cardVariantsY} className='max-md:flex bg-white md:hidden overflow-hidden flex-col max-md:h-[180px] relative  md:rounded-[32px] rounded-3xl  border-none p-3 md:py-4 md:col-span-7'>
                        <div className='w-[60%] md:min-h-[200px] flex flex-col gap-3 pl-3 py-4'>
                            <p className='md:text-3xl font-medium text-2xl'>Seamless Collaboration</p>
                            <p className='secondary-textcolor text-sm max-md:hidden '>AssignIt simplifies teamwork by letting you delegate tasks, track progress, and communicate in real-time—all in one platform. It keeps your team connected, whether they're in the office or worldwide.</p>
                        </div>
                        <div className='absolute bottom-[10%] max-sm:bottom-[0%] right-[10%] max-sm:right-[15%]'>
                            <SubHomeComponent3 />
                        </div>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    )
}

const SubHomeComponent3 = () => {
   
    return (
        <motion.div exit="hidden" initial="hidden" animate="visible" className="relative w-[150px] h-[150px] max-md:h-28 max-md:w-28">
            <motion.img variants={imageVariants} transition={{ delay: 0.2 }} className="h-16 w-16 max-md:w-12 max-md:h-12 absolute top-[-15%] right-[5%] object-cover rounded-full" loading="lazy" alt="" src="/assets/home/ellipse-16@2x.png" />
            <motion.img variants={imageVariants} transition={{ delay: 0.6 }} className="h-16 w-16 max-md:w-12 max-md:h-12 absolute bottom-[20%] right-[35%] object-cover rounded-full" alt="" src="/assets/home/ellipse-15@2x.png" />
            <motion.img variants={imageVariants} transition={{ delay: 0.6 }} className="h-16 w-16 max-md:w-12 max-md:h-12 absolute bottom-[20%] right-[-25%] object-cover rounded-full" loading="lazy" alt="" src="/assets/home/ellipse-17@2x.png" />
            <div className="h-14 w-14 max-md:w-10 max-md:h-10   absolute bottom-[40%] right-[8%]  rounded-full bg-indigo-600 flex flex-row items-center justify-center p-2 box-border z-[1]">
                <motion.img variants={imageVariants} transition={{ delay: 0.6 }} className="h-8 w-8 relative overflow-hidden shrink-0" alt="" src="/assets/home/heroiconssolidplus.svg" /></div>
            <motion.img variants={imageVariants} transition={{ delay: 0.8 }} className="h-[25px] w-[25px] max-md:h-[15px] max-md:w-[15px] absolute max-md:top-[18%] max-md:left-[40%] top-[13%] left-[30%]" loading="lazy" alt="" src="/assets/home/vector-626.svg" />
            <motion.img variants={imageVariants} transition={{ delay: 0.9 }} className="w-[25px] h-[25px] max-md:h-[15px] max-md:w-[15px] absolute max-md:top-[18%] max-md:right-[-8%]  top-[13%] right-[-15%] object-contain" loading="lazy" alt="" src="/assets/home/vector-627.svg" />

        </motion.div>)
}
    
export default HomeComponent3;

