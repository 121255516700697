import { Input } from '../../ui/input'
import { Button } from '../../ui/button'
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {  useState } from 'react'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "../../../components/ui/form"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BlogComponent3 = () => {

    return (
        <div className='wrapper'id={"joinBeta"}>
            <div className='relative md:my-5 overflow-hidden md:max-h-[530px] max-h-[600px] rounded-3xl md:p-10 p-1 py-6  bg-gradient-to-l from-lime-200 to-lime-500 '>
                <div className="grid md:grid-cols-2  grid-cols-1 gap-6 max-md:gap-12 relative z-[10]">
                    <div className='text-white md:space-y-7 space-y-4 max-md:px-4 '>
                        <h1 className="md:font-semibold font-medium  md:text-[44px] md:leading-[1.2] max-w-[400px]  text-2xl ">
                            Join Our Beta Community
                        </h1>
                        <FormSubmit />
                        {/*   <Input value={email} onChange={e => setEmail(e.target.value)} className='h-[50px] max-w-[400px] text-black rounded-full focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent' placeholder='Enter Your Email' />
                        <div className='max-w-[400px]'>
                            <Button onClick={handleSendMessage} className='buttonColor h-[50px] relative z-10 rounded-full md:px-6  hover:bg-indigo-700 max-md:w-full '>Send Message</Button>
                        </div> */}
                    </div>
                    <div className='max-md:my-4 max-md:h-[150px] relative'>
                        <img className="absolute z-0 max-md:hidden md:bottom-[-45px] md:right-[-70px] xl:bottom-[-50px] xl:right-[-110px] " alt="" src="/assets/blog/vector1.svg" />
                        <img className="absolute z-0 max-md:hidden md:top-[-40px] md:right-[-70px]  xl:top-[-60px]   xl:right-[-100px] " alt="" src="/assets/blog/vector2.svg" />
                        <img className="absolute z-0 max-md:hidden md:top-[-40px] md:right-[-70px] xl:top-[-60px]   xl:right-[-100px] " alt="" src="/assets/blog/vector3.svg" />
                        <img className="absolute z-0 max-md:hidden md:top-[-40px] md:right-[-70px] xl:top-[-60px]   xl:right-[-100px] " alt="" src="/assets/blog/vector4.svg" />
                        <img className="absolute z-0 max-md:hidden md:top-[10px] md:right-[-70px]  xl:top-[-10px]   xl:right-[-100px] " alt="" src="/assets/blog/vector5.svg" />

                        <img className="absolute z-0 md:hidden top-[-70px] right-[-10px]" alt="" src="/assets/blog/vector6.svg" />
                        <img className="absolute z-0 md:hidden top-[-50px] right-[-10px]" alt="" src="/assets/blog/vector7.svg" />
                        <img className="absolute z-0 md:hidden top-[-20px] right-[-10px]" alt="" src="/assets/blog/vector8.svg" />
                        <img className="absolute z-0 md:hidden top-0 right-[-10px]" alt="" src="/assets/blog/vector9.svg" />
                        <img className="absolute z-0 md:hidden top-5 right-[-20px]" alt="" src="/assets/blog/vector10.svg" />

                        <div className="absolute top-0 max-md:top-[-10%] right-0">
                            <SubBlogComponent1 />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


const formSchema = z.object({
    email: z.string().email({ message: "Invalid email address" }).min(2).max(50),
});


const FormSubmit = () => {
    const [loading, setLoading] = useState(false);
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: "",
        },
    })
    const {reset}=form;

    async function onSubmit(values: z.infer<typeof formSchema>) {
        setLoading(true);
        const formUrl = 'https://script.google.com/macros/s/AKfycbwwqyu9FCT3ipZ0EMmBOp2MqpAWv_kmxq65C0vh_Vw7Cz2cqGkPOUftz5zmMybCDcnY/exec';
        try {
            const response = await fetch(formUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'email': values.email
                }).toString()
            });
           reset()
            if (response.ok) {
                toast.success('Email Submitted successfully!', {
                    autoClose: 2000,
                    style: {
                      background: 'linear-gradient(to right, #93eb99, #7cef31)', // Lime gradient
                      color: '#ffffff',
                    },
                  });
            }
        } catch (error) {
            toast.error('Failed to send Email.', {
                autoClose: 2000,
                style: {
                  background: 'linear-gradient(to right, #FF0000, #DC143C)', // Red gradient
                  color: '#ffffff',
                },
              });
        } finally {
            setLoading(false); // Set loading to false when submission ends
          }
    }
    return <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input  {...field} className='h-[50px] max-w-[400px] text-black rounded-full focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent' placeholder='Enter Your Email' />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <div className='max-w-[400px]'>
                <Button type='submit' className='buttonColor h-[50px] relative z-10 rounded-full md:px-6 text-base hover:bg-indigo-700 max-md:w-full w-[150px]'  disabled={loading}>{loading ? 'Joining...' : 'Join'} </Button>
            </div>
        </form>
    </Form>
}
const SubBlogComponent1 = () => {
    return <div className='relative w-[200px] h-[200px]'>
        <img className="absolute max-w-24 z-0 " alt="" src="/assets/blog/Ellipse727.svg" />
        <img className="absolute max-w-[70px] top-3 z-0 right-[33px]" alt="" src="/assets/blog/Ellipse728.svg" />
        <img className="absolute max-w-16 top-[74px] left-[69px] " alt="" src="/assets/blog/Ellipse729.svg" />
        <img className="absolute max-w-12 z-0  right-[20px] top-[77px]" alt="" src="/assets/blog/Ellipse731.svg" />
        <img className="absolute max-w-16 z-0 top-24 left-[8px]" alt="" src="/assets/blog/Ellipse732.svg" />
        <img className="absolute max-w-12 z-0 bottom-4 left-[62px]" alt="" src="/assets/blog/Ellipse733.svg" />
        <img className="absolute max-w-16 z-0 right-[26px] bottom-[13px]" alt="" src="/assets/blog/Ellipse730.svg" />
        <p className='text-white absolute z-10 right-[86px] top-[94px]'>You</p>
    </div>
}


export default BlogComponent3

