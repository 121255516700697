import React from 'react'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const imageVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.6, ease: 'easeInOut' } }
};

const AboutComponent1 = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });

  return (
    <div className='space-y-4 wrapper'>
      <div className='text-center'>
        <p className='mx-auto px-4 py-1 bg-gradient-to-l font-semibold rounded-full from-lime-200 to-lime-500 text-white border-8 border-lime-100 inline-block'>
          About
        </p>
      </div>
      <h3 className='text-xl text-center md:text-5xl font-semibold'>Our Story</h3>
      <div className='w-full md:py-4'>
        <motion.img
          ref={ref}
          variants={imageVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className='w-full'
          src="/assets/about/about-main-img1.png"
          alt="about"
        />
      </div>
    </div>
  );
};

// const AboutComponent1 = () => {
//   const location = useLocation()
//   const trimmedPathname = location.pathname.startsWith('/') ? location.pathname.slice(1) : location.pathname;

//   return (<div className='space-y-4 wrapper'>
//     <div className='text-center'>
//       <p className='mx-auto px-4 py-1 bg-gradient-to-l  font-semibold rounded-full from-lime-200 to-lime-500 text-white border-8 border-lime-100 inline-block'>
//         About
//       </p>

//     </div>

//     <h3 className='text-xl text-center md:text-5xl font-semibold'>Our Story</h3>
//     <div className='w-full md:py-4'>
//     <img className='w-full ' src="/assets/about/about-main-img.png" alt="about"/>
//     </div>
//   </div>)
// }

export default AboutComponent1