import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../ui/sheet";
import { Separator } from "../../ui/separator";
import { HiMiniBars3BottomRight } from "react-icons/hi2";
import { MobileLinks, scrollToTarget } from "../../../constants/index";
import {  NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { IoLogoYoutube } from "react-icons/io";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";


const MobileNav = () => {
  const [open,setOpen]=useState(false);
  const handleClose = ()=>{
    setOpen(false)
  }
  const navigate = useNavigate();
  const location = useLocation();

 const handleJoinBetaClick = () => {
        scrollToTarget('joinBeta', 2000); 
      handleClose()
  };

  return (
    <nav className="md:hidden sticky top-0">
      <Sheet key="right"  open={open} onOpenChange={()=>{
        if(!open){
          setOpen(true)
        }else{
          handleClose()
        }
      }}>
        <SheetTrigger className="align-middle">
          <HiMiniBars3BottomRight  size="26px" color="black" />
        </SheetTrigger>
        <SheetContent className="md:hidden  space-y-5" side="right">

          <NavLink to="/">
            <img
              className="h-8 w-[124.2px] relative"
              loading="lazy"
              alt=""
              src="/assets/home/group-19.svg"
            />
          </NavLink>
          <Separator className="border border-gray-100" />  
            <NavItemsMobile handleClose={handleClose}/>
          <div className="flex flex-col items-start justify-center gap-6 md:hidden">
            <Button variant={"link"} onClick={handleJoinBetaClick}  className="textColor px-2 font-medium">
            Join Beta
            </Button>
          </div>
          {/* <div className="px-10 fixed bottom-6 ">
          <div className="flex gap-4  ">
                <IoLogoYoutube size={20} />
                <FaInstagram size={20} />
                <FaFacebookF size={20} />
                <FaLinkedin  size={20} />
              </div>
              </div> */}
        </SheetContent>
      </Sheet>
    </nav>
  );
};



const NavItemsMobile = ({handleClose}:{handleClose:() => void}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
    {MobileLinks.map((links: any) => {
          const isActive = location.pathname === links.route;
          
          return (<div key={links.id}>
          <div className="flex justify-between items-center">
             <Button onClick={()=>{navigate(links.route);handleClose()}} variant={"link"}
                className={` font-medium w-full flex justify-between gap-4
                  ${isActive ? 'text-black' : 'secondary-textcolor'}
                `}
              >{ links.name}
              <span className="flex justify-end">
                  <img src="/assets/home/inclined.png" alt="arrow" className="w-4 h-4" />
                </span>
              </Button>
        
              </div>
              <Separator />

              </div>);
        })}
    </>
  );
}
/* 
const NavItemsMobile = () => {
  const location = useLocation();

  return (
    <>
    {MobileLinks.map((links: any) => {
          const isActive = location.pathname === links.route;
          
          return (<div key={links.id}>
          <div className="flex justify-between items-center">
             <Link 
                to={links.route} 
                className={`
                  ${navigationMenuTriggerStyle()} 
                  font-medium 
                  ${isActive ? 'text-black' : 'secondary-textcolor'}
                `}
              >{ links.name}</Link>
             <Link  to={links.route} > 
             <span className="flex justify-end">
                  <img src="/assets/home/inclined.png" alt="arrow" className="w-4 h-4" />
                </span></Link>
              
              </div>
              <Separator />

              </div>);
        })}
    </>
  );
} */




export default MobileNav;

