import { IoLogoYoutube } from "react-icons/io";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { Separator } from "../../ui/separator";
import { Link, NavLink } from "react-router-dom";
import { Company, OthersF, Product } from "../../../constants";
import { MdCopyright } from "react-icons/md";


// const Footer = () => {

//   return (
//     <footer className=" border-gray-100 border-t-2 xl:px-2" style={{ backgroundColor: "#F9F9F9" }}>
//       <div className="wrapper">
//         <div className="flex md:flex-row flex-col flex-wrap justify-between py-3 pb-6">
//           <div className="space-y-3 max-sm:pb-6">
//             <NavLink to="/">
//               <img
//                 className="h-8 w-[124.2px] relative"
//                 loading="lazy"
//                 alt=""
//                 src="/assets/home/group-19.svg"
//               />
//             </NavLink>
//             {/*             <p className="max-w-[350px]">Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero amet imped</p>
//  */}          </div>
//           <div className="sm:flex flex-wrap md:gap-28 gap-10 max-sm:grid grid-cols-2">
//             <div className="flex flex-col gap-2">
//               <h4 className="font-medium text-lg">Product</h4>
//               {Product.map(product => <Link className="text-sm font-medium secondary-textcolor hover:primary-textcolor" key={product.id} to={product.route}>{product.name}</Link>)}
//             </div>
//             <div className="flex flex-col gap-2">
//               <h4 className="font-medium text-lg">Company</h4>
//               {Company.map(product => <Link className="text-sm font-medium secondary-textcolor hover:primary-textcolor" key={product.id} to={product.route}>{product.name}</Link>)}
//             </div>
//             {/* <div className="flex flex-col gap-2">
//               <h4 className="font-medium text-lg">Others</h4>
//               {OthersF.map(product => <Link className="text-sm font-medium secondary-textcolor hover:primary-textcolor" key={product.id} to={product.route}>{product.name}</Link>)}
//             </div> */}
//             <div className="flex flex-col gap-2">
//               <h4 className="font-medium text-lg">Contact</h4>
//               {/*  <p className="text-indigo-700 text-sm font-medium">+91 8765432342</p> */}
//               <p className="text-indigo-700 text-sm font-medium">hello@assignit.in</p>
//               <div className="flex gap-4 max-md:hidden">
//                 <IoLogoYoutube size={24} />
//                 <FaInstagram size={24} />
//                 <FaFacebookF size={24} />
//                 <FaLinkedin size={24} />
//               </div>

//             </div>

//           </div>
//         </div>
//         {/*   <Separator className="my-6"/> */}
//         <div className="flex gap-4 md:hidden justify-center">
//           <IoLogoYoutube size={24} />
//           <FaInstagram size={24} />
//           <FaFacebookF size={24} />
//           <FaLinkedin size={24} />
//         </div>
//       </div>

//       <p className="items-center py-4 text-sm  gap-2 flex  justify-center" style={{ backgroundColor: "#F5F5F5" }}><MdCopyright />2020 - 2024 Assignit. All Rights Reserved.</p>

//     </footer>
//   )
// }
const Footer = () => {

  return (
    <footer className=" border-gray-100 border-t-2 xl:px-2" style={{ backgroundColor: "#F9F9F9" }}>
      <div className="wrapper">
        <div className="flex md:flex-row flex-col  md:justify-between justify-center gap-6  items-center py-3 ">
          <div className="">
            <NavLink to="/">
              <img
                className="h-8 w-[124.2px] relative"
                loading="lazy"
                alt=""
                src="/assets/home/group-19.svg"
              />
            </NavLink>
          </div>

            <div className="flex flex-col gap-2 max-md:items-center">
              <h4 className="font-medium text-sm">Email Us</h4>
              <Link to="mailto:hello@assignit.in" className="hover:underline text-indigo-700 text-base font-medium">
              hello@assignit.in
            </Link>
            </div>

          </div>
        </div>


    </footer>
  )
}

export default Footer


// import { motion,useAnimation } from 'framer-motion';
// import { NavLink, Link } from 'react-router-dom';
// import { IoLogoYoutube } from 'react-icons/io';
// import { FaInstagram, FaFacebookF, FaLinkedin } from 'react-icons/fa';
// import { MdCopyright } from 'react-icons/md';
// import { Separator } from "../../ui/separator";
// import { Company, OthersF, Product } from "../../../constants";
// import { useEffect } from 'react';
// import { useInView } from 'react-intersection-observer';


// const Footer = () => {
//   // Controls for the animation
//   const controls = useAnimation();

//   // Hook to track whether the footer is in view
//   const { ref, inView } = useInView({
//     threshold: 0.2, // Trigger when 20% of the footer is in view
//   });

//   // Trigger the animation when the footer comes into view
//   useEffect(() => {
//     if (inView) {
//       controls.start('visible');
//     }
//   }, [controls, inView]);

//   // Define animation variants
//   const containerVariant = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: { staggerChildren: 0.3, duration: 0.5 },
//     },
//   };

//   const itemVariant = {
//     hidden: { opacity: 0, y: 20 },
//     visible: { opacity: 1, y: 0 },
//   };

//   return (
//     <footer ref={ref} className="border-gray-100 border-t-2 xl:px-2 bg-gray-100">
//       <motion.div
//         className="wrapper"
//         variants={containerVariant}
//         initial="hidden"
//         animate={controls}
//       >
//         <motion.div
//           className="flex md:flex-row flex-col flex-wrap justify-between gap-10"
//           variants={itemVariant}
//         >
//           <div className="space-y-3">
//             <NavLink to="/">
//               <motion.img
//                 className="h-8 w-[124.2px] relative"
//                 loading="lazy"
//                 alt=""
//                 src="/assets/home/group-19.svg"
//                 variants={itemVariant}
//               />
//             </NavLink>
//             <motion.p
//               className="max-w-[350px]"
//               variants={itemVariant}
//             >
//               Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero amet imped.
//             </motion.p>
//           </div>
//           <motion.div
//             className="sm:flex flex-wrap md:gap-20 gap-10 max-sm:grid grid-cols-2"
//             variants={itemVariant}
//           >
//             <div className="flex flex-col gap-2">
//               <motion.h4
//                 className="font-semibold text-lg"
//                 variants={itemVariant}
//               >
//                 Product
//               </motion.h4>
//               {Product.map((product) => (
//                 <motion.div variants={itemVariant} key={product.id}>
//                   <Link to={product.route}>{product.name}</Link>
//                 </motion.div>
//               ))}
//             </div>
//             <div className="flex flex-col gap-2">
//               <motion.h4
//                 className="font-semibold text-lg"
//                 variants={itemVariant}
//               >
//                 Company
//               </motion.h4>
//               {Company.map((company) => (
//                 <motion.div variants={itemVariant} key={company.id}>
//                   <Link to={company.route}>{company.name}</Link>
//                 </motion.div>
//               ))}
//             </div>
//             <div className="flex flex-col gap-2">
//               <motion.h4
//                 className="font-semibold text-lg"
//                 variants={itemVariant}
//               >
//                 Others
//               </motion.h4>
//               {OthersF.map((other) => (
//                 <motion.div variants={itemVariant} key={other.id}>
//                   <Link to={other.route}>{other.name}</Link>
//                 </motion.div>
//               ))}
//             </div>
//             <div className="flex flex-col gap-2">
//               <motion.h4
//                 className="font-semibold text-lg"
//                 variants={itemVariant}
//               >
//                 Contact
//               </motion.h4>
//               <motion.p className="text-indigo-500" variants={itemVariant}>
//                 +91 8765432342
//               </motion.p>
//               <motion.p className="text-indigo-500" variants={itemVariant}>
//                 hello@assignit.com
//               </motion.p>
//               <motion.div
//                 className="flex gap-4"
//                 variants={itemVariant}
//               >
//                 <IoLogoYoutube size={20} />
//                 <FaInstagram size={20} />
//                 <FaFacebookF size={20} />
//                 <FaLinkedin size={20} />
//               </motion.div>
//             </div>
//           </motion.div>
//         </motion.div>
//         <motion.div variants={itemVariant}>
//           <Separator className="my-3" />
//           <p className="items-center gap-2 flex justify-center">
//             <MdCopyright /> 2020 - 2024 Assignit. All Rights Reserved.
//           </p>
//         </motion.div>
//       </motion.div>
//     </footer>
//   );
// };

// export default Footer;

