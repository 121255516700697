import { Button } from "../../../components/ui/button"

const AboutComponent2 = () => {

  return (<div className='wrapper'>
    <div className='grid md:grid-cols-2 grid-cols-1  gap-6 md:gap-10'>
      <div className="space-y-4 md:space-y-6 max-md:text-center ">
        <p className="md:text-[44px] md:leading-[1.2] max-md:text-3xl font-medium md:font-semibold">The Spark Behind <span style={{ color: "rgb(133, 196, 65)" }}> AssignIt</span>, Where Simplicity Meets 
        <span style={{ color: "rgb(133, 196, 65)" }}> Efficiency</span> </p>
    {/*     <Button className="buttonColor h-[50px] rounded-full max-md:hidden  hover:bg-indigo-700  md:px-12 px-8 md:text-lg md:font-medium md:py-6">
                    Join Beta
                </Button> */}
      </div>
      <div className="space-y-4 secondary-textcolor text-justify ">
    <p>AssignIt was created to simplify task management for everyone, evolving from a personal tool for our team into a solution for managing multiple tasks, deadlines, and responsibilities. Born out of the need for an intuitive and robust system that seamlessly integrates into daily routines, AssignIt was crafted to transform productivity without the complexities of traditional tools. It’s not just another task management tool but a reflection of our belief that productivity should be simple and enjoyable, allowing you to focus more on accomplishing tasks and less on managing them. We’re excited to share this journey and empower you to achieve more with ease.</p>
{/* <p>Our journey began in the heart of our team’s workspace, where we needed something more intuitive than the overly complex systems that dominated the market. We envisioned a tool that was both robust and easy to use, something that would fit seamlessly into our daily routines without a steep learning curve. We took our experiences, Frustration, and insights, and molded them into AssignIt—a task management tool that’s designed to grow with you, whether you’re managing a small personal project or leading a large team</p>
     <p>AssignIt isn’t just another task management tool; it’s a reflection of our belief that productivity should be simple and enjoyable. Every feature is built with the user in mind, ensuring that you spend less time figuring out how to manage your tasks and more time actually getting things done. We’re excited to share this journey with you and can’t wait to see how AssignIt will empower you to achieve more with ease.</p>
      */} </div>
  {/*     <Button className="buttonColor h-[50px] rounded-full md:hidden max-w-40 mx-auto hover:bg-indigo-700  md:px-12 px-8 md:text-lg md:font-medium md:py-6">
            Join Beta
        </Button> */}
    </div>
  </div>)
}

export default AboutComponent2