import {  cardVariantsXN } from "../../../../constants";
import { motion } from "framer-motion";
const FeatureStep2 = () => {
    return (<div className='relative'>
        <div className=' relative grid md:grid-cols-2 z-10 grid-cols-1 gap-10 items-center '>
            <div className='md:space-y-5 md:px-12 px-4'>
                {/* <p className='text-indigo-500'>Checklist Mastery</p>
                <p className='text-indigo-500 md:text-7xl text-3xl font-semibold'>02</p> */}
                 <p className='text-indigo-500 inline-block rounded-full text-[12px] bg-white p-2 px-3 '>Checklist Mastery</p>
               <p className="text-3xl md:text-7xl font-semibold bg-gradient-to-t from-indigo-400 to-indigo-500 bg-clip-text text-transparent">
                   02
               </p>
                <p className='md:text-3xl font-semibold text-2xl max-md:font-medium'>Break Big Tasks into Bite-Sized Steps!</p>
                <p className='sm:text-sm secondary-textcolor '>Manage large tasks by adding or removing sub-tasks or checklist items as you progress.</p>
            </div>
            <div className='flex justify-start items-end '>
                <motion.div className='relative' initial="hidden"  viewport={{ once: false, amount: 0.4 }} transition={{ staggerChildren: 0.3 }} whileInView="visible">
                    <img className=" max-h-[600px]  md:w-full w-[70%] mx-auto  object-cover" loading="lazy" src="/assets/product/step2phone.png" alt="phone" />
                    <motion.img variants={cardVariantsXN} className="xl:max-w-[325px] max-xl:w-[50%] top-[-10%] absolute md:top-0 left-[50%] max-h-full object-cover max-w-full" alt="" src="/assets/product/step2messages.png" />
                </motion.div>

            </div>
        </div>
        <div className='bg-gradient-to-t z-0 absolute md:bottom-0 bottom-8 from-white to-indigo-50 p-4 rounded-3xl w-full h-full  md:h-[400px] lg:h-[500px]'></div>
    </div>
    )
}


export default FeatureStep2