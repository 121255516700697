
const ContactComponent1 = () => {

  return (<div className='space-y-4 wrapper'>
    <div className='text-center'>
      <p className='mx-auto px-4 py-1 bg-gradient-to-l  font-semibold rounded-full from-lime-200 to-lime-500 text-white border-8 border-lime-100 inline-block'>
        Contact
      </p>

    </div>

{/*     <h3 className='text-xl text-center md:text-[44px] md:leading-[1.2] font-semibold'>Common Questions Answered</h3>
 */}  </div>)
}

export default ContactComponent1