import { cardVariantsY } from "../../../../constants";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from 'react-intersection-observer'; 

const FeatureStep4 = () => {
    return (<div className='relative '>
        <div className=' relative grid md:grid-cols-2 z-10 grid-cols-1 gap-10 items-center '>
            <div className='md:space-y-5 md:px-12 px-4'>
               {/*  <p className='text-indigo-500'>Insights Dashboard</p>
                <p className='text-indigo-500 md:text-7xl text-3xl font-semibold'>04</p> */}
                 <p className='text-indigo-500 inline-block rounded-full text-[12px] bg-white p-2 px-3 '>Insights Dashboard</p>
               <p className="text-3xl md:text-7xl font-semibold bg-gradient-to-t from-indigo-400 to-indigo-500 bg-clip-text text-transparent">
                   04
               </p>
                <p className='md:text-3xl font-semibold text-2xl max-md:font-medium'>Stay in Control with Clear, Actionable Insights!</p>
                <p className='sm:text-sm secondary-textcolor '>Toggle between essential KPIs and visual summaries with a single tap, keeping you effortlessly
                    in control of your project’s progress.</p>
            </div>
            <div className='flex justify-center items-end '>
                <div className='relative'>
                    <img className=" max-h-[600px]  md:w-full w-[70%] mx-auto  object-cover" loading="lazy" src="/assets/product/step4phone.png" alt="phone" />
                    <motion.div className="absolute top-16 right-0 lg:right-[-25%] md:right-[-15%]"  initial="hidden"  viewport={{ once: false, amount: 0.4 }} transition={{ staggerChildren: 0.3 }} whileInView="visible">
                        <SubFeatureStep1 />
                    </motion.div>
                    <motion.div className="absolute bottom-32 left-[-5%] md:left-[-35%]"initial="hidden"  viewport={{ once: false, amount: 0.4 }} transition={{ staggerChildren: 0.3 }} whileInView="visible">
                        <SubFeatureStep2 />
                    </motion.div>
                </div>

            </div>
        </div>
        <div className='bg-gradient-to-t z-0 absolute md:bottom-0 bottom-8 from-white to-indigo-50 p-4 rounded-3xl w-full h-full  md:h-[550px] lg:h-[500px]'></div>
    </div>
    )
}

const SubFeatureStep1 = () => {
    return (
        <motion.div variants={cardVariantsY} className='relative md:w-[160px] md:h-[160px] w-[90px] h-[90px] bg-white rounded-xl'>
            <img className=" w-[80%] z-10 absolute top-[10%] left-[10%] max-h-full object-cover max-w-full" alt="" src="/assets/product/Group36.svg" />
            <img className=" max-h-full z-10 top-[1%] left-[1%] absolute object-cover  max-w-full" alt="" src="/assets/product/Ellipse725.svg" />
            <img className=" w-[85%] z-10 max-h-full top-[8%] left-[7%] absolute object-cover max-w-full" alt="" src="/assets/product/Ellipse726.svg" />
            <p className='w-6  h-6 rounded-full z-20  top-[50%] left-0 absolute px-[3px] py-[5px] bg-white ' style={{ fontSize: "10px" }}>46%</p>
            <p className='w-6  h-6 rounded-full  z-20 top-0 xl:top-[10%] right-[20%] absolute px-[3px] py-[5px] bg-white ' style={{ fontSize: "10px" }}>29%</p>
            <p className='w-6  h-6 rounded-full z-20 xl:right-[10%] right-0 bottom-[20%] absolute px-[3px] py-[5px] bg-white ' style={{ fontSize: "10px" }}>23%</p>
        </motion.div>
    )
}
const SubFeatureStep2 = () => {
    const [count, setCount] = useState(0);
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.4 });

  useEffect(() => {
    if (inView) {
      const countUp = () => {
        let start = 0;
        const end = 100; // Final value
        const duration = 2000; // Duration of animation in milliseconds
        const incrementTime = duration / end; // Time between increments

        const interval = setInterval(() => {
          start += 1;
          setCount(start);

          if (start === end) {
            clearInterval(interval);
          }
        }, incrementTime);
      };

      countUp();
    }
  }, [inView]);
    return (
        <motion.div ref={ref} variants={cardVariantsY} className='relative p-2 flex gap-3 bg-white rounded-xl'>
            <img className=" w-12 h-12 p-2  max-h-full object-cover max-w-full" alt="" src="/assets/product/award.svg" />
            <div className='space-y-2'>
                <p className='text-orange-500 md:text-lg' >{count}</p>
                <p className='text-sm' >Tasks Completed</p>
            </div>

        </motion.div>
    )
}

/* const SubFeatureStep2 = () => {
    return (
        <div className='relative p-2 flex gap-3 bg-white rounded-xl'>
            <img className=" w-12 h-12 p-2  max-h-full object-cover max-w-full" alt="" src="/assets/product/award.svg" />
            <div className='space-y-2'>
                <p className='text-orange-500 md:text-lg' >100</p>
                <p className='text-sm' >Tasks Completed</p>
            </div>

        </div>
    )
} */
export default FeatureStep4