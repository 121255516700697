import Header from "../components/shared/RootLayout/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/shared/RootLayout/Footer";
import { useEffect, useState } from "react";


const MainNavigation = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col relative font-display primary-textcolor">
      <Header scrolled={scrolled}/>
      <div className="min-h-[500px] mt-20">
      <Outlet />
      </div>
      <Footer/>
    </div>
  );
};

export default MainNavigation;
