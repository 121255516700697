import { cardVariantsX } from "../../../../constants";
import { motion } from "framer-motion";
const FeatureStep1 = () => {
    return (<div className='relative '>
        <div className=' relative grid md:grid-cols-2 z-10 grid-cols-1 gap-10 items-center '>
            <div className='md:space-y-5 md:px-12 px-4'>
                <p className='text-indigo-500 inline-block rounded-full text-[12px] bg-white p-2 px-3 '>Create Simple Tasks</p>
               
                {/* <p className='text-indigo-500 md:text-7xl text-3xl font-semibold'>01</p> */}
                <p className="text-3xl md:text-7xl font-semibold bg-gradient-to-t from-indigo-400 to-indigo-500 bg-clip-text text-transparent">
                    01
                </p>
                <p className='md:text-3xl font-semibold text-2xl max-md:font-medium'>Effortlessly Create and Delegate Tasks to Yourself and Others!</p>
                <p className='sm:text-sm secondary-textcolor '>Quickly create tasks that can be independent or linked to projects, adapting to your needs.</p>
            </div>
            <div className='flex justify-center items-end '>
                <div className='relative'>
                    <img className=" max-h-[600px]  md:w-full w-[70%] mx-auto  object-cover" loading="lazy" src="/assets/product/step1phone.png" alt="phone" />
                    <div className="absolute top-[70px]  right-16">
                        <SubFeatureStep1 />
                    </div>
                </div>

            </div>
        </div>
        <div className='bg-gradient-to-t z-0 absolute md:bottom-0 bottom-8 from-white to-indigo-50 p-4 rounded-3xl w-full h-full  md:h-[550px] lg:h-[500px]'></div>
    </div>
    )
}

const SubFeatureStep1 = () => {
    return (
        <motion.div   initial="hidden"  viewport={{ once: false, amount: 0.4 }} transition={{ staggerChildren: 0.3 }} whileInView="visible"  className='relative md:w-[360px] md:h-[360px] w-[250px] h-[250px]'>
            <motion.img variants={cardVariantsX} className="md:w-[344px] w-[80%] absolute top-0 left-0  max-h-full md:top-4 object-cover max-w-full" alt="" src="/assets/home/task1.png" />
            <motion.img variants={cardVariantsX} className="md:w-[322px] w-[80%]  md:top-[40%] top-[32%] md:right-0 max-h-full right-[16%] absolute object-cover  max-w-full" alt="" src="/assets/home/task2.png" />
            <motion.img variants={cardVariantsX} className="md:w-80  w-[80%]  bottom-[-2%] right-[8%] md:right-[-10%] max-h-full md:bottom-[-15.5%] absolute object-cover max-w-full" alt="" src="/assets/home/task3.png" />
        </motion.div>
    )
}

// const SubFeatureStep1 = () => {
//     return (
//         <div className='relative md:w-[360px] md:h-[360px] w-[250px] h-[250px]'>
//             <img className="md:w-[344px] w-[80%] absolute top-0 left-0 max-h-full object-cover max-w-full" alt="" src="/assets/home/task1.png" />
//             <img className="md:w-[322px] w-[80%] max-h-full md:top-[36%] top-[32%] md:right-0 right-[16%] absolute object-cover  max-w-full" alt="" src="/assets/home/task2.png" />
//             <img className="md:w-80  w-[80%] max-h-full bottom-[-2%] right-[8%] md:right-[-10%] md:bottom-[-13%] absolute object-cover max-w-full" alt="" src="/assets/home/task3.png" />
//         </div>
//     )
// }
export default FeatureStep1