import { Button } from "../../ui/button"
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';



 const AboutComponent3 = () => {

  return (<div className='wrapper md:my-10 my-6'>
    <div className="grid md:grid-cols-2 grid-cols-1 gap-6 max-md:relative">
      <div className="flex justify-end max-md:hidden max-h-[240px] ">
        <div className="relative bg-gradient-to-r from-white to-lime-100 rounded-3xl overflow-hidden md:w-[290px] ">
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[-8px] z-10 left-0 max-w-48 max-h-48"
            src="/assets/about/arrow.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[20px]  z-0 md:right-[-30px] "
            src="/assets/about/vector1.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[40px]  z-0 md:right-[-30px] "
            src="/assets/about/vector2.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[45px]  z-0 md:right-[-30px] "
            src="/assets/about/vector3.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[50px]  z-0 md:right-[-30px] "
            src="/assets/about/vector4.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[60px]  z-0 md:right-[-30px] "
            src="/assets/about/vector5.svg"
          />
        </div>
      </div>
      <div className=" overflow-hidden relative  space-y-6 rounded-3xl  bg-gray-50 max-md:rounded-br-none p-3 md:py-16 md:px-10">
        <h1 className="flex relative items-center gap-6 text-xl md:text-4xl font-semibold z-10">Our Mission
          <img alt="leftarrow" className="w-10 h-10 md:w-12 md:h-12" src="/assets/about/ourmission1.svg" />
        </h1>
        <p className="z-10 relative secondary-textcolor">To help individuals and teams organize their work more effectively, so they can focus on what
          truly matters-getting things done without the hassle.</p>
        <img alt="design" className="w-full h-full  overflow-hidden shrink-0 absolute top-[-40px] left-0 md:left-[50px] max-w-[390px] max-h-[200px] z-0 " src="/assets/about/ourmission.svg" />
      </div>
      <div className="h-10  bg-gray-50  w-[50%] flex justify-end absolute -bottom-10 rounded-b-3xl right-0 md:hidden"></div>
    </div>
    <div className="md:hidden grid grid-cols-2 gap-4 my-6">
      <div className="relative md:hidden h-[200px] bg-gradient-to-r from-white to-lime-100 rounded-3xl overflow-hidden md:w-[290px] ">
        <img
          alt="design"
          className="w-full h-full absolute bottom-[-30px] z-10 left-[-20px] max-w-48 max-h-48"
          src="/assets/about/arrow.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute bottom-[40px]  z-0 md:right-[-30px] "
          src="/assets/about/vector1.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute bottom-[45px]  z-0 md:right-[-30px] "
          src="/assets/about/vector2.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute bottom-[50px]  z-0 md:right-[-30px] "
          src="/assets/about/vector3.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute bottom-[55px]  z-0 md:right-[-30px] "
          src="/assets/about/vector4.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute bottom-[60px]  z-0 md:right-[-30px] "
          src="/assets/about/vector5.svg"
        />
      </div>
      <div className="relative md:hidden mt-8 h-[200px] bg-gradient-to-r from-white to-lime-100 rounded-3xl  overflow-hidden md:w-[290px] ">
        <img
          alt="design"
          className="w-full h-full absolute md:bottom-[-8px] z-10 left-0 max-w-48 max-h-48"
          src="/assets/about/vision2.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute md:bottom-[40px]  z-0 md:right-[-20px] "
          src="/assets/about/vector11.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute md:bottom-[30px]  z-0 md:right-[-20px] "
          src="/assets/about/vector12.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute md:bottom-[26px]  z-0 md:right-[-23px] "
          src="/assets/about/vector13.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute md:bottom-[22px]  z-0 md:right-[-26px] "
          src="/assets/about/vector14.svg"
        />
        <img
          alt="design"
          className="w-full h-full absolute md:bottom-[18px]  z-0 md:right-[-30px] "
          src="/assets/about/vector15.svg"
        />
      </div>
    </div>
    <div className="grid md:grid-cols-2 grid-cols-1 gap-6 max-md:relative">
    <div className="h-10  bg-gray-50  w-[50%] flex justify-end absolute -top-9 rounded-t-3xl left-0 md:hidden"></div>
      <div className=" overflow-hidden relative  space-y-6 rounded-3xl bg-gray-50  p-3 md:py-16 md:px-10  max-md:rounded-tl-none">
        <h1 className="flex relative items-center gap-6 text-xl md:text-4xl font-semibold z-10">Our Vision
          <img alt="leftarrow" className="w-10 h-10 md:w-12 md:h-12 " src="/assets/about/vision1.svg" />
        </h1>
        <p className="z-10 relative secondary-textcolor">To redefine task management by creating tool that is not only powerful and versatile but also incredibly easy to use, making productivity accessible to everyone.</p>
        <img alt="design" className="w-full h-full  overflow-hidden shrink-0 absolute top-[0px] left-0 md:left-[50px] max-w-[390px] max-h-[200px] z-0 " src="/assets/about/vision.svg" />
      </div>
      <div className="flex justify-start  pl-8 pt-8 max-md:hidden  ">
        <div className="relative bg-gradient-to-r from-white to-lime-100 rounded-3xl  overflow-hidden md:w-[290px] ">
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[-8px] z-10 left-0 max-w-48 max-h-48"
            src="/assets/about/vision2.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[40px]  z-0 md:right-[-20px] "
            src="/assets/about/vector11.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[30px]  z-0 md:right-[-20px] "
            src="/assets/about/vector12.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[26px]  z-0 md:right-[-23px] "
            src="/assets/about/vector13.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[22px]  z-0 md:right-[-26px] "
            src="/assets/about/vector14.svg"
          />
          <img
            alt="design"
            className="w-full h-full absolute md:bottom-[18px]  z-0 md:right-[-30px] "
            src="/assets/about/vector15.svg"
          />
        </div>
      </div>
    </div>
  </div>)
}

export default AboutComponent3