import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<StrictMode>
  <HelmetProvider>
    <App />
    <ToastContainer />
  </HelmetProvider>
</StrictMode>)
