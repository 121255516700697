import * as React from "react";
import { cn } from "../../../lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../../ui/navigation-menu";
import { subHeaderLinks } from "../../../constants/index";
import { Link } from "react-router-dom";


export function SubNavigation() {
  return (
      <NavigationMenu>
          <NavigationMenuList className="flex md:flex-row gap-6 flex-col">
              {subHeaderLinks.map((link) => (
                  <NavigationMenuItem key={link.id} className="relative">
                      <Link 
                          to={link.route} 
                          className="font-medium text-base px-2 relative NavlinkHover"
                      >
                          {link.name}
{/*                           <span className="absolute left-0 bottom-[-2px] w-0 h-[2px] bg-lime-500 transition-all duration-300 group-hover:w-full group-hover:left-0 "></span>
 */}                      </Link>
                  </NavigationMenuItem>
              ))}
          </NavigationMenuList>
      </NavigationMenu>
  );
}


const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none  space-y-1  rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground ",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm  leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})

ListItem.displayName = "ListItem"