export const subHeaderLinks = [
    {
        id: 1,
        name: "Home",
        route: "/"
    },
    {
        id: 2,
        name: "About",
        route: "/about"
    },
    {
        id: 3,
        name: "Features",
        route: "/features"
    },
    /* {
        id: 4,
        name: "Blogs",
        route: "/blogs"
    }, */
    {
        id: 5,
        name: "Contact",
        route: "/contact"
    }
]

export const MobileLinks = [
    {
        id: 1,
        name: "Home",
        route: "/"
    },
    {
        id: 2,
        name: "About",
        route: "/about"
    },
    {
        id: 3,
        name: "Features",
        route: "/features"
    },
   /*  {
        id: 4,
        name: "Blogs",
        route: "/blogs"
    }, */
    {
        id: 5,
        name: "Contact",
        route: "/contact"
    }
]


export const Product = [
    {
        id: 1,
        name: "Home",
        route: "/"
    },
    {
        id: 2,
        name: "Product",
        route: "/product"
    },
    {
        id: 3,
        name: "Blogs",
        route: "/blogs"
    },
]

export const Company = [
    {
        id: 1,
        name: "About",
        route: "/about"
    },
    {
        id: 2,
        name: "Blogs",
        route: "/blogs"
    },
    {
        id: 3,
        name: "Contact",
        route: "/contact"
    },
]

export const OthersF = [
    {
        id: 1,
        name: "Download Android",
        route: "/download-andriod"
    },
    {
        id: 2,
        name: "Download IOS",
        route: "/download-ios"
    },
    {
        id: 3,
        name: "Privacy & Policy",
        route: "/privacy-policy"
    },
]

export const contactNavItems = ['General', 'Product', 'Pricing', 'Support'];

export const generalQuestions = [
    {
      id: 1,
      questionName: "What is AssignIt?",
      answer: "AssignIt is a task management tool that helps teams collaborate efficiently."
    },
    {
      id: 2,
      questionName: "How does AssignIt help with team collaboration?",
      answer: "AssignIt provides a platform for teams to assign tasks, track progress, and communicate effectively."
    },
    {
      id: 3,
      questionName: "Do I need an account to complete tasks?",
      answer: "Yes, you need to create an account to access and complete tasks on AssignIt."
    },
    {
      id: 4,
      questionName: "Is there a free trial available?",
      answer: "Yes, AssignIt offers a free trial for new users to explore its features."
    },
    {
      id: 5,
      questionName: "Lorem ipsum dolor sit amet consectetur?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      id: 6,
      questionName: "Lorem ipsum dolor sit amet consectetur?",
      answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }
  ];
  
  export const productQuestions = [
    {
      id: 1,
      questionName: "What features does AssignIt offer?",
      answer: "AssignIt offers features like task assignment, progress tracking, team collaboration, and reporting."
    },
    {
      id: 2,
      questionName: "Can AssignIt integrate with other tools?",
      answer: "Yes, AssignIt can integrate with various tools like Slack, Google Drive, and Trello."
    }
  ];
  
  export const pricingQuestions = [
    {
      id: 1,
      questionName: "What are the pricing plans for AssignIt?",
      answer: "AssignIt offers various pricing plans, including a free tier, standard plan, and enterprise plan."
    },
    {
      id: 2,
      questionName: "Is there a discount for annual subscriptions?",
      answer: "Yes, AssignIt offers a discount for users who choose an annual subscription."
    }
  ];
  
  export const supportQuestions = [
    {
      id: 1,
      questionName: "How can I contact AssignIt support?",
      answer: "You can contact AssignIt support via email, chat, or phone."
    },
    {
      id: 2,
      questionName: "What is the response time for support queries?",
      answer: "The typical response time for support queries is within 24 hours."
    }
  ];
  
export  const recentBlogPosts = [
    {
      id: 1,
      title: "Mastering Public Speaking: Tips and Tricks",
      date: "22 Aug, 2024",
      imageUrl: "/assets/blog/blog1.png"
    },
    {
      id: 2,
      title: "Effective Team Collaboration in 2024",
      date: "25 Aug, 2024",
      imageUrl: "/assets/blog/blog2.png"
    },
    {
      id: 3,
      title: "Top Digital Marketing Trends to Watch",
      date: "28 Aug, 2024",
      imageUrl: "/assets/blog/blog3.png"
    },
    {
      id: 4,
      title: "Streamlining Your Project Management Process",
      date: "30 Aug, 2024",
      imageUrl: "/assets/blog/blog4.png"
    },
    {
      id: 5,
      title: "Embracing Remote Work: Challenges and Solutions",
      date: "01 Sep, 2024",
      imageUrl: "/assets/blog/blog5.png"
    },
    {
      id: 6,
      title: "Balancing Career and Personal Life",
      date: "03 Sep, 2024",
      imageUrl: "/assets/blog/blog6.png"
    },
    {
      id: 7,
      title: "Innovative Strategies for Business Growth",
      date: "05 Sep, 2024",
      imageUrl: "/assets/blog/blog7.png"
    }
  ];
  

  export  const imageVariants = {
    hidden: { opacity: 0, scale: 0.5, rotate: -45 },
    visible: {
        opacity: 1,
        scale: 1,
        rotate: 0,
        transition: { duration: 0.8, type: 'spring', bounce: 0.5 }
    },
    
};

export const cardVariantsY = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut" } },
  exit: { opacity: 0, y: -50, transition: { duration: 0.8, ease: "easeInOut" } },
};
export const cardVariantsX = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeInOut" } },
  exit: { opacity: 1, x: -50, transition: { duration: 0.8, ease: "easeInOut" } },
};
export const cardVariantsXN = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeInOut" } },
  exit: { opacity: 0, x: 50, transition: { duration: 0.8, ease: "easeInOut" } },
};

export const smoothScrollVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.8, ease: "easeInOut" } },
  exit: { opacity: 0, x: -50, transition: { duration: 0.8, ease: "easeInOut" } },
};

export const scrollToTarget= (targetId: string, duration: number, callback?: () => void) => {
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    setTimeout(() => {
      const extraScroll = window.location.pathname === '/features' ? 250 : 200;
      const targetRect = targetElement.getBoundingClientRect();
      const targetBottomPosition = targetRect.bottom + window.scrollY + extraScroll; // Bottom of the element
      const startPosition = window.scrollY;
      const distance = targetBottomPosition - startPosition;
      const startTime = performance.now();

      const scroll = (currentTime: number) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        window.scrollTo(0, startPosition + distance * easeInOutQuad(progress));

        if (elapsed < duration) {
          requestAnimationFrame(scroll);
        } else if (callback) {
          callback(); // Optional callback after the scroll finishes
        }
      };

      const easeInOutQuad = (t: number) => {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      };

      requestAnimationFrame(scroll);
    }, 300); // Adjust this delay for framer-motion animation duration
  }
};




/* export const scrollToTarget = (targetId: string, duration: number) => {
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    const targetPosition = targetElement.offsetTop;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    const startTime = performance.now();

    const scroll = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      window.scrollTo(0, startPosition + distance * easeInOutQuad(progress));

      if (elapsed < duration) {
        requestAnimationFrame(scroll);
      }
    };

    const easeInOutQuad = (t: number) => {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    };

    requestAnimationFrame(scroll);
  }
};
 */